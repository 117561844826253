import React, { useState, useEffect } from 'react'

import * as webix from 'webix/webix.js'
import 'webix/webix.css';
import { login } from './login'
import { MENU_ITEMS } from './menuitems'
import { copy } from 'webix';
require('./App.css')
window.webix = webix

// window.webix.debug(1)
webix.ui.fullScreen();
let loaded = false
const MENU_HEIGHT = 30

require('./custom_components')

function loadMenu(permission) {
    let items = []
    Object.keys(MENU_ITEMS).forEach((header, iheader) => {
        if (permission.indexOf(header.replace(' Menu', '')) >= 0) {
            MENU_ITEMS[header].forEach((menuitem, imenuitem) => {
                items.push({ id: header + '.' + menuitem[0], value: menuitem[0] })
            })
        }
    })
    MENU_ITEMS['Account'].forEach((menuitem, imenuitem) => {
        items.push({ id: 'Account.' + menuitem[0], value: menuitem[0] })
    })
    if (window.webix.$$('menulist')) {
        window.webix.$$('menulist').clearAll()
        window.webix.$$('menulist').parse(items)
    }
    else {
        return items
    }
}

function App() {
    const [permission, setPermission] = useState(['Admin'])
    let items = loadMenu(permission)

    useEffect(() => {
        loadMenu(permission)
        //if ( permission.length ) alert('permission changed: ' + permission.join(', '))
    }, [permission])
    useEffect(() => {
        // code to run after render goes here
        if (!loaded) {
            webix.protoUI({
                name:"tooltipButton",
                $cssName:"button",
                $init:function(obj){
                    if (obj.tooltip) this.$view.title = obj.tooltip;
                }
            }, webix.ui.button)
  
            loaded = true
            window.webix.ui({
                view: 'scrollview',
                id: 'mainapp',
                scroll: 'auto',
                body: {
                    rows: [
                        {
                            view: 'toolbar',
                            height: MENU_HEIGHT,
                            borderless: true,
                            css: 'toolbar',
                            cols: [
                                {
                                    view: 'button',
                                    type: 'icon',
                                    css: 'hamburger',
                                    height: 30,
                                    width: 30,
                                    click: function () {
                                        if (window.webix.$$('menu').isVisible()) {
                                            window.webix.$$('menu').hide()
                                        }
                                        else {
                                            window.webix.$$('menu').show()
                                        }
                                    }
                                },
                                {
                                    view: 'template',
                                    borderless: true,
                                    id: 'header',
                                    css: 'header',
                                    template: 'Please Log In or Create Account',
                                }
                            ]
                        },
                        {
                            id: 'pages',
                            width: window.innerWidth,
                            css: 'page',
                            rows: [
                                login({ setPermission })
                            ]
                        }
                    ]
                }
            })

            webix.ui({
                view: "sidemenu",
                id: 'menu',
                width: 200,
                height: window.innerHeight - MENU_HEIGHT,
                css: 'menu',
                top: MENU_HEIGHT,
                body: {
                    view: 'unitlist',
                    id: 'menulist',
                    borderless: true,
                    scroll: 'auto',
                    data: items,
                    select: true,
                    uniteBy: function (obj) {
                        return obj.id.indexOf('.') >= 0 ? obj.id.split('.')[0] : ''
                    },
                    on: {
                        onItemClick: function (a, b) {
                            let item = MENU_ITEMS[a.split('.')[0]].filter(x => x[0] == a.split('.')[1])[0]
                            Object.values(window.webix.ui.views).forEach(comp => {
                                if (comp.config.view === "window") {
                                    comp.destructor()
                                }
                            })
                            window.webix.$$('pages').removeView(window.webix.$$('pages').getChildViews()[0])
                            let props = {
                                permission,
                                setPermission
                            }
                            window.webix.$$('header').setHTML(a.indexOf('User Menu') >= 0 ? a.split('.')[1] : a.split('.').join(' - '))
                            window.webix.$$('pages').addView(item[1](props))
                            window.webix.$$('menu').hide()
                        },
                    },
                },
            })
            window.addEventListener('resize', function () {
                window.webix.$$('mainapp').resize()
            })
        }
    }, [])
}

export default App;
