import { verifyForm, formatDollar, formatNumberEditor, formatDollarEditor, formatPhone, sendCommand, verifyField } from '../apis'
import { calculate_rrule, from_rrule } from '../calculate_rrule'
import { buildEditors } from '../custom_components'
import { manageSessions } from '../studios/manageSessions'
import { manageLocations, manageRooms } from '../studios/manageLocations'

window.setwhichmonthlyform = (e) => {
    if (e == 'relative') {
        //window.webix.$$('relativeDay').enable()
        window.webix.$$('weekdays').enable()
            ;['relativeDay_first', 'relativeDay_second', 'relativeDay_third', 'relativeDay_fourth', 'relativeDay_last'].forEach(f => {
                window.webix.$$(f).enable()
            })
        //id: 'weekdays',
        window.webix.$$('bymonthday').disable()
    }
    else {
        //window.webix.$$('relativeDay').disable()
        ;['relativeDay_first', 'relativeDay_second', 'relativeDay_third', 'relativeDay_fourth', 'relativeDay_last'].forEach(f => {
            window.webix.$$(f).disable()
        })
        window.webix.$$('weekdays').disable()
        window.webix.$$('bymonthday').enable()
        //window.processDropdowns()
    }
}

function showFees() {
    let reg = window.webix.$$('new_registration_cost').getValue()
    let tuition = window.webix.$$('new_class_cost_installments').getValue()
    reg = parseFloat(reg.replace(/[^0-9\.]/g, ''))
    tuition = parseFloat(tuition.replace(/[^0-9\.]/g, ''))
    let freq = window.webix.$$('new_cost_reoccurance').getValue()
    sendCommand('calculateFees', {cost: reg}).then(regcosts => {
        regcosts = regcosts.result
        sendCommand('calculateFees', {cost: tuition}).then(tuitioncosts => {
            tuitioncosts = tuitioncosts.result
            let cost = `<table style="width: 460px" class="centercells"><th></th><th>Registration</th><th>${freq} Tuition</th></tr>
            <tr><th>CC Processing Fee (Bank charged):</th><td>${formatDollar(regcosts.squareFee)}</td><td>${formatDollar(tuitioncosts.squareFee)}</td></tr>
            <tr><th>Studio Director Processing Fee:</th><td>${formatDollar(regcosts.ourFee)}</td><td>${formatDollar(tuitioncosts.ourFee)}</td></tr>
            <tr><th>Your Revenue:</th><td>${formatDollar(regcosts.profit)}</td><td>${formatDollar(tuitioncosts.profit)}</td></tr></table>`
            document.getElementById('regfees').innerHTML = cost
        })
    })
}

function verifyAgeFields() {
    let required = false
    if (window.webix.$$('new_min_age').getValue() || window.webix.$$('new_max_age').getValue()) required = true
    if (required) {
        verifyField(window.webix.$$('new_age_cutoff_date'), 1, 'saveClassButton')
    }
    else {
        if (window.webix.$$('new_age_cutoff_date').getNode().classList.contains('invalid')) window.webix.$$('new_age_cutoff_date').getNode().classList.remove('invalid')
        window.webix.$$('new_age_cutoff_date').config.invalid = false
    }
    let invalid = false
    Object.values(window.webix.$$('new_age_cutoff_date').getFormView().elements).forEach(field => {
        invalid = invalid || (('invalid' in field.config) ? field.config.invalid : false)
    })
    if (invalid) {
        window.webix.$$('saveClassButton').disable()
    }
    else {
        window.webix.$$('saveClassButton').enable()
    }
}

async function resetform(studioinfo) {
    let fd
    await sendCommand('getDataForClassesForm', studioinfo).then(formdata => {
        fd = formdata
        window.webix.$$('new_studio_session_id').define('options', [{ id: 0, value: 'Not Part of a Session' }].concat(formdata.result.sessions.map(x => { return { id: x.studio_session_id, value: x.studio_session_name } })))
        window.webix.$$('new_studio_session_id').refresh()
        window.webix.$$('new_studio_location_id').define('options', (formdata.result.locations || []).map(x => { return { id: x.studio_location_id, value: x.studio_location_name } }))
        window.webix.$$('new_studio_location_id').refresh()
        let studio_location_id = window.webix.$$('new_studio_location_id').getValue()
        window.webix.$$('new_room_id').define('options', formdata.result.rooms.filter(x => x.studio_location_id == studio_location_id).map(x => { return { id: x.room_id, value: x.room_name } }))
        window.webix.$$('new_room_id').refresh()
    })
    console.log('updated formdata', fd)
    return fd
}

function newClass(studioinfo, classinfo, formdata) {
    buildEditors()
    if (window.webix.$$('new_class_win')) window.webix.$$('new_class_win').destructor()
    if (window.webix.$$('_datepicker')) window.webix.$$('_datepicker').destructor()
    // get session info
    // get locations
    // get room info
    window.webix.ui({
        view: 'window',
        id: 'new_class_win',
        head: 'Create Class',
        close: true,
        escHide: true,
        position: 'center',
        width: 650,
        height: window.innerHeight - 75,
        body: {
            view: "scrollview",
            scroll: "y",
            width: 650,
            height: window.innerHeight - 75,
            body: {
                view: 'form',
                width: 500,
                id: 'newStudioClassForm',
                elements: [
                    {
                        id: 'new_studio_id',
                        name: 'studio_id',
                        value: studioinfo.studio_id,
                        hidden: true,
                    },
                    {
                        id: 'new_class_id',
                        name: 'class_id',
                        hidden: true,
                    },
                    {
                        view: 'text',
                        id: 'new_class_name',
                        name: 'class_name',
                        label: 'Class Name',
                        css: 'invalid',
                        width: 460,
                        labelWidth: 150,
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('new_class_name').getInputNode().onkeyup = () => verifyField(this, 1, 'saveClassButton')
                            },
                        }
                    },
                    {
                        view: 'textarea',
                        id: 'new_class_description',
                        name: 'class_description',
                        label: 'Class Description',
                        width: 460,
                        height: 50,
                        labelWidth: 150,
                        attributes: { maxlength: 500 }
                    },
                    {
                        cols: [
                            {
                                view: 'combo',
                                id: 'new_studio_location_id',
                                label: 'Studio Location',
                                name: 'studio_location_id',
                                css: 'invalid',
                                options: (formdata.result.locations || []).map(x => { return { id: x.studio_location_id, value: x.studio_location_name } }),
                                width: 430,
                                labelWidth: 150,
                                on: {
                                    onChange: (studio_location_id) => {
                                        window.webix.$$('new_room_id').setValue()
                                        window.webix.$$('new_room_id').define('options', formdata.result.rooms.filter(x => x.studio_location_id == studio_location_id).map(x => { return { id: x.room_id, value: x.room_name } }))
                                        window.webix.$$('new_room_id').refresh()
                                        verifyField(window.webix.$$('new_studio_location_id'), 1, 'saveClassButton')
                                    },
                                    onAfterRender: () => {
                                        if (classinfo) {
                                            window.webix.$$('new_studio_location_id').setValue(classinfo.studio_location_id)
                                            verifyField(window.webix.$$('new_studio_location_id'), 1, 'saveClassButton')

                                        }
                                        else if (formdata.result.locations.length == 1) {
                                            window.webix.$$('new_studio_location_id').setValue(formdata.result.locations[0].studio_location_id)
                                            verifyField(window.webix.$$('new_studio_location_id'), 1, 'saveClassButton')
                                        }
                                    }
                                }
                            },
                            {
                                view: 'button',
                                type: 'icon',
                                width: 30,
                                height: 20,
                                icon: 'wxi-pencil',
                                tooltip: 'Edit Sessions',
                                click: () => { manageLocations(studioinfo, async () => { formdata = await resetform(studioinfo); console.log('formdata is', formdata) }) }
                            }
                        ]
                    },
                    {
                        view: 'combo',
                        id: 'new_room_id',
                        label: 'Studio Room',
                        name: 'room_id',
                        css: 'invalid',
                        options: ['a', 'b'],
                        width: 460,
                        labelWidth: 150,
                        on: {
                            onChange: (room_id) => {
                                verifyField(window.webix.$$('new_room_id'), 1, 'saveClassButton')
                            },
                        }
                    },
                    {
                        cols: [
                            {
                                view: 'combo',
                                id: 'new_studio_session_id',
                                label: 'Session',
                                name: 'studio_session_id',
                                width: 430,
                                labelWidth: 150,
                                options: [{ id: 0, value: 'Not Part of a Session' }].concat(formdata.result.sessions.map(x => { return { id: x.studio_session_id, value: x.studio_session_name } })),
                                value: 0,
                                on: {
                                    onChange: (studio_session_id) => {
                                        if (studio_session_id) {
                                            let sess = formdata.result.sessions.filter(x => x.studio_session_id == studio_session_id)
                                            if (sess && sess.length) {
                                                sess = sess[0]
                                                if (sess.studio_session_start_date) {
                                                    let [year, month, day] = sess.studio_session_start_date.split('-');
                                                    window.webix.$$('new_studio_session_start_date').setValue(sess.studio_session_start_date)
                                                    window.webix.$$('new_override_start_date').setValue(sess.studio_session_start_date)
                                                }
                                                else {
                                                    window.webix.$$('new_studio_session_start_date').setValue('')
                                                    window.webix.$$('new_override_start_date').setValue('')
                                                }
                                                if (sess.studio_session_end_date) {
                                                    let [year, month, day] = sess.studio_session_end_date.split('-');
                                                    window.webix.$$('new_studio_session_end_date').setValue(sess.studio_session_end_date)
                                                    //window.webix.$$('new_override_end_date').setValue(createDate(year, month-1, day))
                                                    window.webix.$$('new_override_end_date').setValue(sess.studio_session_end_date)
                                                }
                                                else {
                                                    window.webix.$$('new_studio_session_end_date').setValue('')
                                                    window.webix.$$('new_override_end_date').setValue('')
                                                }
                                            }
                                            else {
                                                window.webix.$$('new_studio_session_start_date').setValue('')
                                                window.webix.$$('new_studio_session_end_date').setValue('')
                                                window.webix.$$('new_override_start_date').setValue('')
                                                window.webix.$$('new_override_end_date').setValue('')
                                            }
                                        }
                                    },
                                }
                            },
                            {
                                view: 'button',
                                type: 'icon',
                                width: 30,
                                height: 20,
                                icon: 'wxi-pencil',
                                tooltip: 'Edit Sessions',
                                click: () => { manageSessions(studioinfo, async () => { formdata = await resetform(studioinfo); console.log('formdata is', formdata) }) }
                            }
                        ]
                    },
                    {
                        view: 'text',
                        id: 'new_studio_session_start_date',
                        name: 'studio_session_start_date',
                        hidden: true,
                    },
                    {
                        view: 'text',
                        id: 'new_studio_session_end_date',
                        name: 'studio_session_end_date',
                        hidden: true,
                    },
                    {
                        cols: [
                            {
                                view: 'datepicker',
                                id: 'new_override_start_date',
                                label: 'Start/End Dates',
                                name: 'override_start_date',
                                css: 'invalid',
                                width: 295,
                                labelWidth: 150,
                                on: {
                                    onAfterRender: function () {
                                        window.webix.$$('new_override_start_date').getInputNode().onkeyup = () => verifyField(this, 1, 'saveClassButton')
                                    },
                                }
                            },
                            {
                                view: 'template',
                                template: '-',
                                borderless: true,
                                width: 30,
                                css: { 'padding-top': '10px' },
                            },
                            {
                                view: 'datepicker',
                                id: 'new_override_end_date',
                                label: '',
                                name: 'override_end_date',
                                css: 'invalid',
                                width: 135,
                                labelWidth: 0,
                                on: {
                                    onAfterRender: function () {
                                        window.webix.$$('new_override_end_date').getInputNode().onkeyup = () => verifyField(this, 1, 'saveClassButton')
                                    },
                                }
                            },
                        ]
                    },
                    {
                        cols: [
                            {
                                view: 'datepicker',
                                type: 'time',
                                id: 'new_start_time',
                                label: 'Start/End Times',
                                name: 'start_time',
                                css: 'invalid',
                                width: 295,
                                labelWidth: 150,
                                on: {
                                    onAfterRender: function () {
                                        window.webix.$$('new_start_time').getInputNode().onkeyup = () => verifyField(this, 1, 'saveClassButton')
                                    },
                                }
                            },
                            {
                                view: 'template',
                                template: '-',
                                borderless: true,
                                width: 30,
                                css: { 'padding-top': '10px' },
                            },
                            {
                                view: 'datepicker',
                                type: 'time',
                                id: 'new_end_time',
                                label: '',
                                name: 'end_time',
                                css: 'invalid',
                                width: 135,
                                labelWidth: 0,
                                on: {
                                    onAfterRender: function () {
                                        window.webix.$$('new_end_time').getInputNode().onkeyup = () => verifyField(this, 1, 'saveClassButton')
                                    },
                                }
                            },
                        ]
                    },
                    {
                        id: 'freq',
                        view: 'select',
                        value: 'Weekly',
                        label: "Frequency",
                        width: 375,
                        options: ['Monthly', 'Weekly', 'Daily'],
                        on: {
                            onChange: freq => {
                                ;['Monthly', 'Weekly', 'Daily'].forEach(x => window.webix.$$(x).hide())
                                window.webix.$$(freq).show()
                            }
                        }
                    },
                    {
                        id: 'Daily',
                        hidden: true,
                        rows: [
                            {
                                cols: [
                                    {
                                        id: 'daily_interval',
                                        label: 'Every',
                                        view: 'select',
                                        options: ['1', '2', '3', '4', '5', '6', '7'],
                                        labelWidth: '65',
                                        width: 120,
                                    },
                                    {
                                        view: 'template',
                                        template: 'day(s)',
                                        css: { 'font-weight': 500, 'font-family': "Roboto, sans-serif", 'font-size': '14px', 'padding-top': '5px' },
                                        width: 65,
                                        borderless: true,
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        hidden: false,  // defaults to Weekly
                        id: 'Weekly',
                        rows: [
                            {
                                cols: [
                                    {
                                        label: 'Every',
                                        view: 'select',
                                        id: 'weekly_interval',
                                        options: ['1', '2', '3', '4', '5', '6', '7', '8'],
                                        labelWidth: '65',
                                        width: 120,
                                    },
                                    {
                                        view: 'template',
                                        template: 'week(s)',
                                        css: { 'font-weight': 500, 'font-family': "Roboto, sans-serif", 'font-size': '14px', 'padding-top': '5px' },
                                        width: 65,
                                        borderless: true,
                                    }
                                ]
                            },
                            {
                                view: 'template',
                                template: 'Selected Days of Week:',
                                borderless: true,
                                height: 25,
                            },
                            {
                                cols: [
                                    { view: "toggle", name: "su", label: "Sunday", width: 60, css: 'dayselector', id: 'weekly_weekdays_su' },
                                    { view: "toggle", name: "mo", label: "Monday", width: 60, css: 'dayselector', id: 'weekly_weekdays_mo' },
                                    { view: "toggle", name: "tu", label: "Tuesday", width: 60, css: 'dayselector', id: 'weekly_weekdays_tu' },
                                    { view: "toggle", name: "we", label: "Wednesday", width: 80, css: 'dayselector', id: 'weekly_weekdays_we' },
                                    { view: "toggle", name: "th", label: "Thursday", width: 70, css: 'dayselector', id: 'weekly_weekdays_th' },
                                    { view: "toggle", name: "fr", label: "Friday", width: 60, css: 'dayselector', id: 'weekly_weekdays_fr' },
                                    { view: "toggle", name: "sa", label: "Saturday", width: 80, css: 'dayselector', id: 'weekly_weekdays_sa' },
                                ]
                            }
                        ]
                    },
                    {
                        hidden: true,
                        id: 'Monthly',
                        rows: [
                            //{
                            //cols: [
                            //{
                            //id: 'monthly_interval',
                            //label: 'Every',
                            //view: 'select',
                            //options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                            //labelWidth: '65',
                            //width: 120,
                            //},
                            //{
                            //view: 'template',
                            //template: 'month(s)',
                            //css: {'font-weight': 500, 'font-family': "Roboto, sans-serif", 'font-size': '14px', 'padding-top': '5px'},
                            //width: 65,
                            //borderless: true,
                            //}
                            //]
                            //},
                            {
                                cols: [
                                    {
                                        view: 'template',
                                        template: "<input type='radio' id='whichmonthlyday' name='whichmonthly' value='day' checked onchange='window.setwhichmonthlyform(\"day\")' />",
                                        width: 50,
                                        css: { 'padding-top': '5px' },
                                        borderless: true,
                                    },
                                    {
                                        view: 'template',
                                        template: 'on days(s)',
                                        width: 100,
                                        borderless: true,
                                    },
                                    {
                                        view: 'datatable',
                                        id: 'bymonthday',
                                        editable: true,
                                        width: 312,
                                        header: false,
                                        borderless: true,
                                        height: 38,
                                        scroll: false,
                                        data: [{ days: '' }],
                                        columns: [
                                            {
                                                header: '',
                                                id: 'days',
                                                tableid: 'bymonthday',
                                                editor: 'daypicker',
                                                css: { border: '1px solid rgb(218, 222, 224)' },
                                                width: 312,

                                                template: row => {
                                                    let ret = []
                                                    console.log('template', row.days)
                                                    if (row.days) {
                                                        row.days.split(',').forEach(x => { ret.push('<div class="pill">' + x + '</div>') })
                                                        console.log('returning', ret.join(''))
                                                        return ret.join('')
                                                    }
                                                    console.log('returning nothing')
                                                    return ''
                                                }
                                            }
                                        ]
                                    },
                                    //{
                                    //view:"multidates",
                                    //name: 'bymonthday',
                                    //label: '',
                                    //},
                                ]
                            },
                            {
                                cols: [
                                    {
                                        view: 'template',
                                        template: "<input type='radio' id='whichmonthlyrelative' name='whichmonthly' value='relative' onchange='window.setwhichmonthlyform(\"relative\")' />",
                                        css: { 'padding-top': '5px' },
                                        width: 50,
                                        height: 25,
                                        borderless: true,
                                    },
                                    {
                                        view: 'template',
                                        template: 'on the',
                                        width: 100,
                                        borderless: true,
                                    },
                                    { view: "toggle", disabled: true, name: "First", label: "First", width: 40, css: 'dayselector', id: 'relativeDay_first' },
                                    { view: "toggle", disabled: true, name: "Second", label: "Second", width: 55, css: 'dayselector', id: 'relativeDay_second' },
                                    { view: "toggle", disabled: true, name: "Third", label: "Third", width: 45, css: 'dayselector', id: 'relativeDay_third' },
                                    { view: "toggle", disabled: true, name: "Fourth", label: "Fourth", width: 50, css: 'dayselector', id: 'relativeDay_fourth' },
                                    { view: "toggle", disabled: true, name: "Last", label: "Last", width: 40, css: 'dayselector', id: 'relativeDay_last' },
                                ]
                            },
                            {
                                cols: [
                                    {
                                        view: 'template',
                                        template: '',
                                        width: 150,
                                        borderless: true,
                                    },
                                    {
                                        id: 'weekdays',
                                        label: '',
                                        labelWidth: 0,
                                        disabled: true,
                                        view: 'select',
                                        width: 203,
                                        options: [
                                            { id: 'Day', value: 'Day' },
                                            //{id: 'Weekday', value: 'Weekday'},
                                            //{id: 'Weekend', value: 'Weekend'},
                                            { id: 'su', value: 'Sunday' },
                                            { id: 'mo', value: 'Monday' },
                                            { id: 'tu', value: 'Tuesday' },
                                            { id: 'we', value: 'Wednesday' },
                                            { id: 'th', value: 'Thursday' },
                                            { id: 'fr', value: 'Friday' },
                                            { id: 'sa', value: 'Saturday' },
                                        ]
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        cols: [
                            {
                                view: 'text',
                                id: 'new_registration_cost',
                                label: 'Registration Cost',
                                name: 'registration_cost',
                                placeholder: 'This is charged when student registers',
                                width: 430,
                                format: formatDollarEditor,
                                labelWidth: 150,
                                on: {
                                    onAfterRender: function () {
                                        window.webix.$$('new_registration_cost').getInputNode().onkeyup = () => verifyField(this, 1, 'saveClassButton')
                                    },
                                    onChange: showFees
                                }
                            },
                            {
                                view: 'template',
                                //template: '<div class="questionmark"> </div>',
                                template: ' ',
                                borderless: true,
                                width: 30,
                                heigth: 25,
                                css: 'nopadding',
                                tooltip: 'Registration Cost is charged once per session, during signup.  Recurring Tuition Cost is charged once per the selected frequency',
                            }
                        ]
                    },
                    {
                        cols: [
                            {
                                view: 'text',
                                id: 'new_class_cost_installments',
                                label: 'Recurring Tuition Cost',
                                name: 'class_cost_installments',
                                format: formatDollarEditor,
                                placeholder: 'This is charged once per frequency',
                                width: 370,
                                labelWidth: 180,
                                on: {
                                    onAfterRender: function () {
                                        window.webix.$$('new_class_cost_installments').getInputNode().onkeyup = () => verifyField(this, 1, 'saveClassButton')
                                    },
                                    onChange: showFees
                                }
                            },
                            {
                                view: 'select',
                                id: 'new_cost_reoccurance',
                                label: '',
                                name: 'cost_reoccurance',
                                width: 90,
                                labelWidth: 0,
                                options: ['Per Class', 'Weekly', 'Monthly', 'Quarterly', 'One Time'],
                                value: 'Monthly',
                                on: {
                                    onAfterRender: function () {
                                        window.webix.$$('new_cost_reoccurance').getInputNode().onkeyup = () => verifyField(this, 1, 'saveClassButton')
                                    },
                                    onChange: showFees
                                }
                            }
                        ]
                    },
                    {
                        view: 'template',
                        css: 'nomargins',
                        borderless: true,
                        width: 460,
                        height: 100,
                        template: '<div id="regfees" style="width: 460px; height: 100px;"/>'
                    },
                    {
                        view: 'text',
                        id: 'new_max_students',
                        label: 'Max # of Students (leave blank for unlimited)',
                        name: 'max_students',
                        format: formatNumberEditor,
                        width: 460,
                        labelWidth: 330,
                    },
                    {
                        view: 'text',
                        id: 'new_min_age',
                        label: 'Class Min Age (leave blank for none)',
                        format: formatNumberEditor,
                        name: 'min_age',
                        width: 460,
                        labelWidth: 330,
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('new_min_age').getInputNode().onkeyup = verifyAgeFields
                            },
                        }
                    },
                    {
                        view: 'text',
                        id: 'new_max_age',
                        label: 'Class Max Age (leave blank for none)',
                        format: formatNumberEditor,
                        name: 'max_age',
                        width: 460,
                        labelWidth: 330,
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('new_max_age').getInputNode().onkeyup = verifyAgeFields
                            },
                        }
                    },
                    {
                        view: 'datepicker',
                        id: 'new_age_cutoff_date',
                        label: ['Age as of Date (cutoff date)'],
                        name: 'age_cutoff_date',
                        width: 460,
                        labelWidth: 330,
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('new_age_cutoff_date').attachEvent('onChange', verifyAgeFields)
                            }
                        }
                    },
                    {
                        view: 'select',
                        id: 'new_sex_limited',
                        label: 'Student Must Be Sex',
                        name: 'sex_limited',
                        width: 460,
                        labelWidth: 150,
                        options: [{ id: '', value: 'All Sexes' }, { id: 'M', value: 'Male Only' }, { id: 'F', value: 'Female Only' }],
                        value: 'All Sexes',
                    },
                    {
                        view: 'datatable',
                        id: 'student_levels',
                        height: 250,
                        width: 460,
                        columns: [
                            {
                                id: 'checked',
                                header: 'Selected',
                                template: (rec) => {
                                    return rec.checked ? "<img src='/checked.png' width='25'/>" : ""
                                },
                                sort: 'string',
                                width: 90
                            },
                            {
                                id: 'student_level_id',
                                hidden: true
                            },
                            {
                                header: 'Level',
                                id: 'level_name',
                                fillspace: 1,
                                sort: 'string',
                            }
                        ],
                        data: formdata.result.levels,
                        on: {
                            onItemClick: function (a, b, c) {
                                let rec = this.getItem(a.row)
                                rec.checked = rec.checked ? 0 : 1
                                this.updateItem(a.row, rec)
                            },
                            onStructureLoad: function () {
                                this.blockEvent()
                                this.sort(function (a, b) {
                                    let acheck = a.checked ? 'A' : 'B'
                                    let bcheck = b.checked ? 'A' : 'B'
                                    return ((acheck + a.level_name) > (bcheck + b.level_name) ? 1 : -1)
                                })
                                this.unblockEvent()
                            },
                        }
                    },
                    {
                        id: 'saveClassButton',
                        view: 'button',
                        value: 'Save Class',
                        disabled: true,
                        width: 200,
                        click: function () {
                            let args = window.webix.$$('newStudioClassForm').getValues()
                            args.studio_id = studioinfo.studio_id
                            args.start_time = args.start_time ? args.start_time.toTimeString().split(' ')[0] : ''
                            args.end_time = args.end_time ? args.end_time.toTimeString().split(' ')[0] : ''
                            args.student_levels = window.webix.$$('student_levels').data.serialize().filter(x => x.checked).map(x => x.student_level_id)
                            let freq = window.webix.$$('freq').getValue()
                            let interval = window.webix.$$(freq.toLowerCase() + '_interval') ? window.webix.$$(freq.toLowerCase() + '_interval').getValue() : '1'
                            let weekdays = []
                            let relativeDay = []
                            let bymonthday
                            if (freq == 'Weekly') {
                                ;['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'].forEach(x => {
                                    if (window.webix.$$('weekly_weekdays_' + x).getValue()) weekdays.push(x)
                                })
                            }
                            if (freq == 'Monthly') {
                                weekdays.push(window.webix.$$('weekdays').getValue())
                                console.log('Array.from', Array.from(document.getElementsByName('whichmonthly')))
                                let whichmonthly = Array.from(document.getElementsByName('whichmonthly')).filter(x => x.checked)[0].value
                                if (whichmonthly == 'relative') {
                                    ;['relativeDay_first', 'relativeDay_second', 'relativeDay_third', 'relativeDay_fourth', 'relativeDay_last'].forEach(f => {
                                        if (window.webix.$$(f).getValue()) relativeDay.push(f.split('_')[1])
                                    })
                                }
                                else {
                                    console.log('days selected:', window.webix.$$('bymonthday').data.serialize()[0].days.split(','))
                                    bymonthday = window.webix.$$('bymonthday').data.serialize()[0].days.split(',')
                                }
                            }
                            console.log('calculate_rrule(', args.override_start_date, args.override_end_date, freq, interval, weekdays, relativeDay, bymonthday, ')')
                            args.rrule = calculate_rrule(args.override_start_date, args.override_end_date, freq, interval, weekdays, relativeDay, bymonthday)
                            console.log('rrule', args.rrule)
                            //freq, daily_interval, weekly_interval, weekly_weekdays_su..., monthly_interval, whichmonthly (input name), bymonthday, relativeDay, weekdays

                            sendCommand('saveStudioClass', args).then(resp => {
                                if (resp.success) {
                                    let classes = resp.result
                                    window.webix.message({ type: 'success', text: 'Studio class created successfully' })
                                    window.webix.$$('admin_classes').clearAll()
                                    window.webix.$$('admin_classes').parse(classes)
                                    window.webix.$$('new_class_win').destructor()
                                }
                                else {
                                    window.webix.message({ type: 'error', text: resp.err_msg })
                                }
                            })
                        }
                    },
                ],
            }
        },
        on: {
            onBeforeShow: () => {
                if (classinfo) {
                    sendCommand('getStudioClasses', { ...studioinfo, ...classinfo }).then(data => {
                        console.log('got studio class info', data)
                        if (data && data.result.length) {
                            let rec = data.result[0]
                            if (!rec.override_start_date) {
                                rec.override_start_date = rec.studio_session_start_date
                            }
                            if (!rec.override_end_date) {
                                rec.override_end_date = rec.studio_session_end_date
                            }
                            window.webix.$$('newStudioClassForm').parse(data.result[0])
                            let levels = rec.student_levels.map(x => x.student_level_id)
                            window.webix.$$('student_levels').data.each(lvlrec => {
                                if (levels.includes(lvlrec.student_level_id)) {
                                    lvlrec.checked = 1
                                    window.webix.$$('student_levels').updateItem(lvlrec.id, lvlrec)
                                }
                            })
                            verifyAgeFields()
                            if (rec.rrule) {
                                let rrule = from_rrule(rec.rrule).origOptions
                                console.log('from_rrule is')
                                console.log(rrule)
                                let freq = {
                                    1: 'Monthly',
                                    2: 'Weekly',
                                    3: 'Daily',
                                }[rrule.freq]
                                let dayToNum = {
                                    0: 'mo',
                                    1: 'tu',
                                    2: 'we',
                                    3: 'th',
                                    4: 'fr',
                                    5: 'sa',
                                    6: 'su',
                                }
                                window.webix.$$('freq').setValue(freq)
                                if (freq == 'Daily') {
                                    window.webix.$$('daily_interval').setValue(String(rrule.interval))
                                }
                                if (freq == 'Weekly') {
                                    Object.values(dayToNum).forEach(x => {
                                        window.webix.$$('weekly_weekdays_' + x).setValue(0)
                                    })
                                    window.webix.$$('Weekly').show()
                                    console.log('got here in weeklyinterval')
                                    console.log(window.webix.$$('weekly_interval'))
                                    console.log(window.webix.$$('weekly_interval').setValue)
                                    window.webix.$$('weekly_interval').setValue(String(rrule.interval))
                                        ; (rrule.byweekday || []).forEach(x => {
                                            console.log('byweekday ' + x, x.weekday)
                                            console.log('value ' + dayToNum[x.weekday] + ' is selected')
                                            console.log('weekly_weekdays_' + dayToNum[x.weekday])
                                            window.webix.$$('weekly_weekdays_' + dayToNum[x.weekday]).toggle()
                                        })
                                }
                                if (freq == 'Monthly') {
                                    window.webix.$$('bymonthday').clearAll()
                                    if (rrule.bymonthday && rrule.bymonthday != -1) {
                                        window.setwhichmonthlyform('day')
                                        document.getElementById('whichmonthlyday').checked = true
                                        console.log('got here', rrule.bymonthday)
                                        if (typeof (rrule.bymonthday) == 'object') {
                                            window.webix.$$('bymonthday').parse([{ days: rrule.bymonthday.join(',') }])
                                            console.log([{ days: rrule.bymonthday.join(',') }])
                                        }
                                        else {
                                            window.webix.$$('bymonthday').parse([{ days: String(rrule.bymonthday) }])
                                            console.log([{ days: String(rrule.bymonthday) }])
                                        }
                                    }
                                    else {
                                        window.webix.$$('bymonthday').parse([{ days: '' }])
                                        window.setwhichmonthlyform('relative')
                                        document.getElementById('whichmonthlyrelative').checked = true
                                        let posdays = {
                                            1: 'first',
                                            2: 'second',
                                            3: 'third',
                                            4: 'fourth',
                                            '-1': 'last',
                                        }
                                        Object.values(posdays).forEach(x => {
                                            window.webix.$$('relativeDay_' + x).setValue(0)
                                        })
                                        if (rrule.bymonthday == -1) {
                                            window.webix.$$('relativeDay_last').toggle()
                                        }
                                        else {
                                            let bysetpos = []
                                            if (typeof (rrule.bysetpos) == 'object') {
                                                bysetpos = rrule.bysetpos
                                            }
                                            else {
                                                bysetpos = [rrule.bysetpos]
                                            }
                                            bysetpos.forEach(x => {
                                                console.log('relativeDay_' + posdays[x], x)
                                                window.webix.$$('relativeDay_' + posdays[x]).toggle()
                                            })
                                            if (rrule.byweekday) {
                                                rrule.byweekday.forEach(x => {
                                                    window.webix.$$('weekdays').setValue(dayToNum[x.weekday])
                                                })
                                            }

                                        }
                                        //byweekday:  [Weekday {weekday: 1, n: undefined}]
                                        /*
                                    { view:"toggle", disabled: true, name:"First", label:"First", width: 40, css: 'dayselector', id: 'relativeDay_first' },
                                    { view:"toggle", disabled: true, name:"Second", label:"Second", width: 55, css: 'dayselector', id: 'relativeDay_second' },
                                    { view:"toggle", disabled: true, name:"Third", label:"Third", width: 45, css: 'dayselector', id: 'relativeDay_third' },
                                    { view:"toggle", disabled: true, name:"Fourth", label:"Fourth", width: 50, css: 'dayselector', id: 'relativeDay_fourth' },
                                    { view:"toggle", disabled: true, name:"Last", label:"Last", width: 40, css: 'dayselector', id: 'relativeDay_last' },
                                    /*
                                        /*
                                            {id: 'Day', value: 'Day'},
                                            {id: 'Weekday', value: 'Weekday'},
                                            {id: 'Weekend', value: 'Weekend'},
                                            {id: 'su', value: 'Sunday'},
                                            {id: 'mo', value: 'Monday'},
                                            {id: 'tu', value: 'Tuesday'},
                                            {id: 'we', value: 'Wednesday'},
                                            {id: 'th', value: 'Thursday'},
                                            {id: 'fr', value: 'Friday'},
                                            {id: 'sa', value: 'Saturday'},
                                        window.webix.$$('weekdays').setValue()
                                        */

                                    }

                                }
                            }
                            verifyForm(window.webix.$$('newStudioClassForm'))

                        }
                    })
                }
            },
        }
    }).show()
}

export function manageClasses(studioinfo) {
    if (window.webix.$$('classes_window')) window.webix.$$('classes_window').destructor()
    sendCommand('getDataForClassesForm', studioinfo).then(formdata => {
        console.log('formdata', formdata)
        window.webix.ui({
            view: 'window',
            id: 'classes_window',
            head: 'Studio Classes',
            close: true,
            escHide: true,
            position: 'center',
            width: window.innerWidth * .8,
            height: window.innerHeight - 75,
            body: {
                rows: [
                    {
                        view: 'toolbar',
                        cols: [
                            {
                                view: "button",
                                width: 150,
                                value: "Create Class",
                                click: () => {
                                    newClass(studioinfo, undefined, formdata)
                                },
                                on: {
                                    onAfterRender: async function () {
                                        sendCommand('getStudioClasses', studioinfo).then(data => {
                                            console.log('studio class data', data)
                                            window.webix.$$('admin_classes').clearAll()
                                            window.webix.$$('admin_classes').parse(data.result)
                                            window.webix.$$('admin_classes').data.attachEvent('onStoreUpdated', async function (id, rec, action) {
                                                if (action == 'save') {  // this is for the checkboxes
                                                    //await updateStudio(rec)
                                                }
                                            })
                                        })
                                    }
                                }
                            },
                            {
                                view: "button",
                                width: 150,
                                value: "Edit Class",
                                click: () => {
                                    let edited = false
                                    window.webix.$$('admin_classes').data.each(thisrow => {
                                        if (!edited && thisrow.selected) {
                                            edited = true
                                            newClass(studioinfo, thisrow, formdata)
                                        }
                                    })
                                },
                                on: {
                                    onAfterRender: async function () {
                                        sendCommand('getStudioClasses', studioinfo).then(data => {
                                            window.webix.$$('admin_classes').clearAll()
                                            window.webix.$$('admin_classes').parse(data.result)
                                            window.webix.$$('admin_classes').data.attachEvent('onStoreUpdated', async function (id, rec, action) {
                                                if (action == 'save') {  // this is for the checkboxes
                                                    //await updateStudio(rec)
                                                }
                                            })
                                        })
                                    }
                                }
                            },
                            {
                                view: 'button',
                                value: 'Delete Class',
                                width: 150,
                                click: function () {
                                    if (window.confirm('Are you sure you want to delete the selected class?')) {
                                        let deleted = false
                                        window.webix.$$('admin_classes').data.each(thisrow => {
                                            if (thisrow.selected) {
                                                sendCommand('deleteStudioClass', { room_id: thisrow.room_id, class_id: thisrow.class_id }).then(resp => {
                                                    if (resp.success) {
                                                        sendCommand('getStudioClasses', studioinfo).then(resp => {
                                                            window.webix.$$('admin_classes').clearAll()
                                                            window.webix.$$('admin_classes').parse(resp.result)
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    }
                                }
                            },
                        ],
                    },
                    {
                        view: 'toolbar',
                        cols: [
                            {
                                view: 'template',
                                template: '<div style="color: #000; font-weight: 50%; padding-top: 6px">Filters:</div>',
                                borderless: true,
                                width: 100,
                            },
                            {
                                width: 350,
                                view: 'select',
                                label: '<div style="padding-left: 20px;">Level:</div>',
                                labelWidth: 80,
                                options: [{ id: '', value: '' }].concat(formdata.result.levels.map(x => { return { id: x.student_level_id, value: x.level_name } })),
                                on: {
                                    onChange: function (val) {
                                        window.webix.$$('admin_classes').filter(function (rec) {
                                            let res = rec.student_levels.map(x => String(x.student_level_id)).indexOf(String(val)) >= 0
                                            console.log('looking for', val, rec.student_levels.map(x => x.student_level_id), res)
                                            return res
                                        });
                                    }
                                }
                            },
                        ]
                    },
                    {
                        view: 'datatable',
                        id: 'admin_classes',
                        sort: true,
                        resizeColumn: true,
                        //headerRowHeight: 30,
                        tooltip: true,
                        sort: true,
                        resizable: true,
                        columns: [
                            {
                                id: 'selected',
                                width: 30,
                                header: '',
                                template: '{common.radio()}'
                            },
                            {
                                id: 'class_id',
                                hidden: true,
                            },
                            {
                                id: 'class_name',
                                header: ['Class Name', { content: 'textFilter' }],
                                sort: 'string',
                                width: 150,
                            },
                            {
                                id: 'class_description',
                                header: 'Class Description',
                                sort: 'string',
                                width: 150,
                            },
                            {
                                id: 'studio_session_name',
                                header: 'Session Name',
                                header: ['Session Name', { content: 'selectFilter' }],
                                sort: 'string',
                                width: 150,
                            },
                            {
                                id: 'override_start_date',
                                header: 'Start Date',
                                sort: 'string',
                                template: row => {
                                    return row.override_start_date || row.studio_session_start_date
                                },
                            },
                            {
                                id: 'override_end_date',
                                header: 'End Date',
                                sort: 'string',
                                template: row => {
                                    return row.override_end_date || row.studio_session_end_date
                                },
                            },
                            {
                                id: 'start_time',
                                header: 'Class Time',
                                width: 150,
                                sort: 'int',
                                template: row => {
                                    let makeTime = (t) => {
                                        let parts = t.split(':')
                                        let am = 'AM'
                                        if (parts[0] == 12) {
                                            am = 'PM'
                                        }
                                        else if (parts[0] > 12) {
                                            am = 'PM'
                                            parts[0] = parts[0] - 12
                                        }
                                        return parseInt(parts[0]) + ':' + (parts[1] || '00') + am
                                    }
                                    if (!row.start_time) return "Not Set"
                                    if (!row.end_time) return makeTime(row.start_time)
                                    return makeTime(row.start_time) + ' - ' + makeTime(row.end_time)
                                }
                            },
                            {
                                id: 'studio_location_name',
                                sort: 'string',
                                header: ['Studio Location', { content: 'selectFilter' }],
                                width: 150,
                            },
                            {
                                id: 'room_name',
                                header: ['Room Name', { content: 'selectFilter' }],
                                sort: 'string',
                                width: 150,
                            },
                            {
                                id: 'registration_cost',
                                header: ['Registration', 'Cost'],
                                sort: 'int',
                                format: formatDollar
                            },
                            {
                                id: 'class_cost_installments',
                                header: ['Recurring', 'Tuition Cost'],
                                sort: 'int',
                                format: formatDollar,
                                width: 110,
                            },
                            {
                                id: 'cost_reoccurance',
                                sort: 'string',
                                header: ['Recurring', 'Period'],
                            },
                            {
                                id: 'max_students',
                                sort: 'int',
                                header: ['Max', 'Students'],
                            },
                            {
                                id: 'enrolled',
                                sort: 'int',
                                header: ['Currently', 'Enrolled'],
                            },
                            {
                                id: 'min_age',
                                sort: 'int',
                                header: ['Min Age', { content: 'textFilter' }],
                                filter: 'number',
                            },
                            {
                                id: 'max_age',
                                sort: 'int',
                                header: ['Max Age', { content: 'textFilter' }],
                            },
                            {
                                id: 'age_cutoff_date',
                                sort: 'date',
                                header: ['Age as of', 'Date'],
                            },
                            {
                                id: 'sex_limited',
                                sort: 'string',
                                header: ['Sex', {
                                    content: 'selectFilter',
                                    options: [{ id: "M", value: "Male" }, { id: "F", value: "Female" }]
                                }],
                                template: row => {
                                    if (row.sex_limited == 'M') return 'Male Only'
                                    if (row.sex_limited == 'F') return 'Female Only'
                                    return 'Not Limited'
                                },
                            },
                        ],
                    }
                ]
            },
        }).show()
    })
}

