import {sendCommand} from '../apis'

export function manageLevels(studioinfo) {
    if (window.webix.$$('levels_window')) window.webix.$$('levels_window').destructor()
    sendCommand('getStudentLevelsForStudio', studioinfo).then(resp => {
        let levels = resp.result
        window.webix.ui({
            view: 'window',
            id: 'levels_window',
            head: 'Studio Levels',
            close: true,
            escHide: true,
            position: 'center',
            width: 600,  //TODO: make this 300 again
            height: 400,
            body: {
                rows: [
                    {
                        view: 'toolbar',
                        height: 30,
                        cols: [
                            {
                                view: 'button',
                                value: 'Create Level',
                                click: function () {
                                    window.webix.$$('studioleveltable').parse({ studio_id: studioinfo.studio_id })
                                    let id = window.webix.$$('studioleveltable').getLastId()
                                    window.webix.$$('studioleveltable').editCell(id, 'level_name', false, true)
                                }
                            },
                            {
                                view: 'button',
                                value: 'Delete Level',
                                click: async function () {
                                    let deleted = false
                                    await window.webix.$$('studioleveltable').data.each(row => {
                                        if ( row.selected ) {
                                            resp = sendCommand('deleteStudentLevel', {...studioinfo, student_level_id: row.student_level_id}).then(resp => {
                                                console.log('resp is', resp)
                                                if ( resp.success ) {
                                                    sendCommand('getStudentLevelsForStudio', studioinfo).then(resp => {
                                                        console.log('getting levels back', resp)
                                                        window.webix.$$('studioleveltable').clearAll()
                                                        window.webix.$$('studioleveltable').parse(resp.result)
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            },
                        ]
                    },
                    {
                        view: 'datatable',
                        editable: true,
                        scroll: 'auto',
                        data: levels,
                        sort: true,
                        id: 'studioleveltable',
                        columns: [
                            {
                                id: 'selected',
                                width: 30,
                                header: '',
                                template: '{common.checkbox()}'
                            },
                            {
                                id: 'studio_id',
                                hidden: true,
                            },
                            {
                                id: 'student_level_id',
                                hidden: true,
                            },
                            {
                                id: 'level_name',
                                header: 'Level Name',
                                width: 270,
                                sort: 'string',
                                editor: 'text',
                            },
                        ],
                        on: {
                            onAfterEditStop: function (value, e, ignore) {
                                if (ignore || ! window.webix.$$('studioleveltable'))  return
                                if (value.value != value.old) {
                                    let rec = window.webix.$$('studioleveltable').getItem(e.row)
                                    console.log('update', rec)
                                    sendCommand('saveStudentLevel', rec).then(resp => {
                                        console.log('resp', resp)
                                        if (resp.success) {
                                            window.webix.message({ type: 'success', text: 'Successfully Changed' })
                                            window.webix.$$('studioleveltable').clearAll()
                                            window.webix.$$('studioleveltable').parse(resp.result)
                                        }
                                        else {
                                            window.webix.message({ type: 'error', text: resp.err_msg })
                                        }
                                    })
                                }
                            }
                        },
                    }
                ]
            },
        }).show()
    })
}