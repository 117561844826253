import { Profiler } from 'react'
import { sendCommand } from './apis'


export function findPerson(studio_id, account_or_student, actioncb) {
    let accountRadio = {
        view: 'radio',
        hidden: account_or_student,
        value: 'account',
        options: [
            { 'id': 'account', 'value': 'Find Account Owner' },
            { 'id': 'student', 'value': 'Find Student' }
        ]
    }
    if (window.webix.$$('findPerson')) window.webix.$$('findPerson').destructor()

    window.webix.ui({
        view: 'window',
        position: 'center',
        height: window.innerHeight * .8,
        width: window.innerWidth * .8,
        head: 'Search for Account',
        close: true,
        escHide: true,
        id: 'findPerson',
        body: {
            view: 'accordion',
            height: window.innerHeight * .8,
            multi: true,
            rows: [
                {
                    view: 'accordionitem',
                    id: 'criteria',
                    header: 'Search Parameters',
                    body: {
                        rows: [
                            {
                                view: 'text',
                                label: 'Cell Phone',
                                id: 'search_cell_phone',
                                name: 'search_cell_phone',
                                width: 275,
                            },
                            accountRadio,
                            {
                                cols: [
                                    {
                                        view: 'text',
                                        label: 'Last Name',
                                        id: 'search_last_name',
                                        name: 'search_last_name',
                                        width: 275,
                                    },
                                    {
                                        view: 'text',
                                        label: 'First Name',
                                        id: 'search_first_name',
                                        name: 'search_first_name',
                                        width: 275,
                                    },
                                ]
                            },
                            {
                                cols: [
                                    {
                                        view: 'text',
                                        label: 'City',
                                        name: 'search_city',
                                        width: 275,
                                    },
                                    {
                                        view: 'text',
                                        label: 'State',
                                        name: 'search_state',
                                        width: 275,
                                    },
                                ]
                            },
                            {
                                view: 'button',
                                width: 200,
                                hotkey: 'enter',
                                value: 'Search',
                                click: async function () {
                                    let search = {
                                        account_or_student: account_or_student || 'account'
                                    }
                                    if ( studio_id ) search.studio_id = studio_id
                                    if ( window.webix.$$('search_cell_phone').getValue() ) {
                                        search.cell_phone = window.webix.$$('search_cell_phone').getValue()
                                    }
                                    if ( window.webix.$$('search_first_name').getValue() ) {
                                        search.first_name = window.webix.$$('search_first_name').getValue()
                                    }
                                    if ( window.webix.$$('search_last_name').getValue() ) {
                                        search.last_name = window.webix.$$('search_last_name').getValue()
                                    }
                                    sendCommand('searchPeople', search).then(resp => {
                                        console.log('search results', resp)
                                        window.webix.$$('searchtable').clearAll()
                                        window.webix.$$('searchtable').parse(resp.result)
                                        if (resp.result.length) window.webix.$$('criteria').collapse()
                                        else window.webix.message({type: 'error', text: 'No results found'})
                                    })
                                }
                            },
                        ]
                    },
                },
                {
                    rows: [
                        {
                            view: 'accordionitem',
                            header: 'Results',
                            body: {
                                view: 'datatable',
                                width: window.innerWidth * .8,
                                id: 'searchtable',
                                resize: true,
                                sort: true,
                                data: [],
                                headerRowHeight: 25,
                                columns: [
                                    { id: 'account_id', hidden: true },
                                    {
                                        id: 'selected',
                                        width: 30,
                                        header: '',
                                        template: '{common.checkbox()}'
                                    },
                                    { id: 'cell_phone', width: 150, header: 'Cell Phone' },
                                    { id: 'first_name', width: 200, header: 'Account First Name' },
                                    { id: 'last_name', width: 200, header: 'Account Last Name' },
                                ],
                            }
                        },
                        {
                            view: 'button',
                            value: 'Add Account',
                            click: function() {
                                let rows = []
                                window.webix.$$('searchtable').data.each(rec => {
                                    if ( rec.selected ) {
                                        delete rec.selected
                                        rows.push(rec)
                                    }
                                })
                                window.webix.$$('findPerson').destructor()
                                actioncb(rows)
                            }
                        }
                    ]
                }
            ]
        }
    }).show()
}