
import { sendCommand } from '../apis'

const appId = 'sandbox-sq0idb-gYL_UnMaFpTR7lJzmqcxdQ';
const locationId = 'G3E74Z0V74SC1';

async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
        return tokenResult.token;
    } else {
        if (tokenResult.errors) {
            window.webix.message({type: 'error', text: tokenResult.errors.map(x => x.message).join('; ')})
        }
        else {
            window.webix.message({type: 'error', text: 'Unknown error from our financial processing vendor'})
        }
        return
    }
}

export const creditCard = (accountinfo, successcb) => {
    let card;
    if (window.webix.$$('ccwindow')) window.webix.$$('ccwindow').destructor()
    if (window.webix.$$('ccs')) window.webix.$$('ccs').destructor()
    sendCommand('getCreditCards', {account_id: accountinfo.account_id}).then(cards => {
        if ( cards.success ) {
            cards = cards.result
        }
        else {
            cards = []
        }
        console.log('cards')
        console.log(cards)
        window.webix.ui({
            view: 'window',
            id: 'ccwindow',
            head: 'Credit Card Info',
            close: true,
            escHide: true,
            position: 'center',
            width: window.innerWidth * .8,
            height: window.innerHeight - 75,
            body: {
                width: window.innerWidth * .8,
                height: window.innerHeight - 75,
                rows: [
                    {
                        view: 'template',
                        template: "Please note: Credit card information is not stored on our servers.  This data is sent to our financial service company, Square, which handles charging your card on our behalf.  By providing your card information, you agree to letting us charge your card for your registration and/or recurring tution fees for the classes, products, and services for which the students under your account are signed up, and that you authorize Square to store your credit card information for future purchases.",
                        width: window.innerWidth * .8,
                        autoheight: true,
                    },
                    {
                        view: 'template',
                        hidden: ! cards.length,
                        template: 'We have the following card on file for you.  If you provide new card details below, it will replace the card we have on file, and all future transactions will be processed using the new card information.',
                        width: window.innerWidth * .8,
                        borderless: true,
                        autoheight: true,
                    },
                    {
                        view: 'datatable',
                        hidden: ! cards.length,
                        autoheight: true,
                        id: 'ccs',
                        width: 470,
                        columns: [
                            {
                                id: 'card_id',
                                hidden: true,
                            },
                            {
                                id: 'brand',
                                header: 'Brand',
                                width: 120,
                            },
                            {
                                id: 'last_4',
                                header: 'Last 4 Digits',
                                width: 120,
                            },
                            {
                                id: 'exp',
                                header: 'Expiration',
                                width: 120,
                            },
                            {
                                id: 'deletecc',
                                header: '',
                                width: 100,
                                format: (row => {
                                    return "<div class='webix_el_button'><button class='webixtype_base'>Delete</button></div>";
                                })
                            },
                        ],
                        data: cards,
                        on: {
                            onItemClick: function (cell) {
                                let row = window.webix.$$('ccs').getItem(cell.row)
                                if (cell.column == 'deletecc') {
                                    sendCommand('deleteCreditCard', {account_id: accountinfo.account_id, card_id: row.card_id}).then(resp => {
                                        if (resp.success) {
                                            let classes = resp.result
                                            window.webix.message({ type: 'success', text: 'Credit card deleted successfully'})
                                            creditCard(accountinfo)
                                        }
                                        else {
                                            window.webix.message({ type: 'error', text: resp.err_msg })
                                        }
                                    })
                                }
                            }
                        }
                    },
                    {
                        view: 'template',
                        template: '<div id="ccplaceholder"/>',
                        height: 60,
                    },
                    {
                        view: 'button',
                        width: 200,
                        position: 'center',
                        value: 'Save Card Info',
                        click: async () => {
                            let token = await tokenize(card)
                            if (token) {
                                sendCommand('saveToken', { ...accountinfo, token }).then(resp => {
                                    console.log('save token result', resp)
                                    window.webix.message({ type: 'success', text: "Credit card info saved on bank's servers (we don't store your card info in our database)" })
                                    if (successcb) successcb()
                                    window.webix.$$('ccwindow').close()
                                })
                            }
                        }
                    },
                ]
            },
            on: {
                onBeforeShow: async () => {
                    async function initializeCard(payments) {
                        const card = await payments.card();
                        await card.attach('#ccplaceholder');
                        return card;
                    }
                    const payments = window.Square.payments(appId, locationId);
                    card = await initializeCard(payments);
                    console.log('card', card)
                }
            }
        }).show()
    })
}