import {sendCommand, STATES} from '../apis'

export function createStudio(selectedId) {
    if (window.webix.$$('admin_studios_new')) {
        window.webix.$$('admin_new_studio').clear()
        window.webix.$$('admin_studios_new').show()
    }
    else {
        window.webix.ui({
            view: 'window',
            id: 'admin_studios_new',
            head: 'Studio Details',
            close: true,
            escHide: true,
            position: 'center',
            width: 400,
            body: {
                view: 'scrollview',
                maxHeight: window.innerHeight * .95,
                body: {
                    view: 'form',
                    id: 'admin_new_studio',
                    elements: [
                        {
                            view: 'text',
                            hidden: true,
                            name: 'studio_id'
                        },
                        {
                            view: 'text',
                            name: 'studio_name',
                            label: 'Studio Name',
                            labelWidth: 130,
                            width: 350
                        },
                        { view: 'text', name: 'studio_address1', label: 'Address', labelWidth: 130, width: 350 },
                        { view: 'text', name: 'studio_address2', label: 'Address2', labelWidth: 130, width: 350 },
                        { view: 'text', name: 'studio_city', label: 'City', labelWidth: 130, width: 350 },
                        { view: 'select', options: STATES, name: 'studio_state', label: 'State', labelWidth: 130, width: 350 },
                        { view: 'text', name: 'studio_zip', label: 'Zip', labelWidth: 130, width: 350 },
                        { view: 'select', options: ['Eastern', 'Central', 'Mountain', 'Pacific', 'Alaska', 'Hawaii'], name: 'studio_timezone', label: 'Timezone', labelWidth: 130, width: 350 },
                        {
                            view: 'text',
                            name: 'phone_number',
                            label: 'Phone Number',
                            labelWidth: 130,
                            width: 350,
                            pattern: { mask: "(###) ###-####", allow: /[0-9]/g },
                        },
                        { view: 'text', name: 'studio_email', label: 'Email', labelWidth: 130, width: 350 },
                        { view: 'checkbox', name: 'open_enrollment', label: 'Public Enrollment?', labelWidth: 130 },
                        { view: 'checkbox', name: 'active', label: 'Active?' },
                        {
                            view: "button",
                            id: 'studio_save_button',
                            value: "Create Studio",
                            hotkey: "enter",
                            click: async function () {
                                let args = window.webix.$$('admin_new_studio').getValues()
                                sendCommand('createStudio', args).then(results => {
                                    console.log(results)
                                    if (!results.success) {
                                        window.webix.message({ type: 'error', text: 'Failed to create/update the studio: ' + results.error, expire: -1 })
                                        return
                                    }
                                    if (results.result.status == 'success') {
                                        if (args.studio_id) {
                                            if (args.id)  selectedId = args.id
                                            console.log('updating record', selectedId, args)
                                            if ( selectedId ) window.webix.$$('admin_studios').updateItem(selectedId, args)
                                            window.webix.message({ type: 'success', text: 'Successfully Updated', expire: 2000 })
                                        }
                                        else {
                                            window.webix.$$('admin_studios').parse(results.result.studio_info)
                                            window.webix.message({ type: 'success', text: 'Successfully Inserted', expire: 2000 })
                                        }
                                        window.webix.$$('admin_studios_new').hide()
                                    }
                                    else {
                                        window.webix.message({ type: 'error', text: 'Failed to create/update the studio: ' + results.result.error, expire: -1 })
                                    }
                                })
                            }
                        },
                    ],
                    on: {
                        onAfterLoad: function () {
                            window.webix.$$('studio_save_button').setValue('Update Studio')
                        }
                    }
                }
            },
            on: {
                onAfterRender: function () {
                    window.webix.$$('studio_save_button').setValue('Create Studio')
                }
            },

        }).show();
    }
}