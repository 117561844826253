import { formatTime, formatDollar, getAge, formatPhone, sendCommand, verifyField, verifyForm } from '../apis'
import { RRule } from 'rrule'
import { rruleToText, from_rrule } from '../calculate_rrule'
import { creditCard } from '../square/ccform'

let timeouthandler = undefined

window.dotooltip = (e, rec) => {
    console.log(e)
    if (timeouthandler) window.clearTimeout(timeouthandler)
    if (rec.reasons && rec.reasons.length) {
        window.tooltip.show({ id: 1, value: rec.reasons.join(', ') }, { x: e.x, y: e.y });
    }
    timeouthandler = window.setTimeout(() => window.tooltip.hide(), 5000)
}

export function students(props, studio_id) {
    let account_id = props.account_id
    console.log('props', props)

    if (window.webix.$$('enrolltooltip')) window.webix.$$('enrolltooltip').destructor()
    window.tooltip = window.webix.ui({
        view: 'tooltip',
        id: 'enrolltooltip',
        template: '#value#',
        height: 80
    });

    sendCommand('getStudentsForAccount', { account_id }).then(resp => {
        let students = resp.result
        console.log('students', students)
        sendCommand('getAccountName', { account_id }).then(account => {
            console.log('account name', account)
            if (window.webix.$$('studentswindow')) window.webix.$$('studentswindow').destructor()
            if (window.webix.$$('studentTable')) window.webix.$$('studentTable').destructor()
            window.webix.ui({
                view: 'window',
                id: 'studentswindow',
                head: 'Students - ' + account.result.first_name + ' ' + account.result.last_name,
                close: true,
                escHide: true,
                position: 'center',
                width: window.innerWidth * .8,
                height: window.innerHeight - 75,
                body: {
                    rows: [
                        {
                            view: 'toolbar',
                            height: 30,
                            cols: [
                                {
                                    view: 'button',
                                    value: 'Add Student',
                                    width: 150,
                                    click: () => {
                                        newStudent(undefined, account_id, studio_id, () => {
                                            sendCommand('getStudentsForAccount', { account_id }).then(resp => {
                                                let students = resp.result
                                                window.webix.$$('studentTable').clearAll()
                                                window.webix.$$('studentTable').parse(students)
                                            })
                                        })
                                    }
                                },
                            ]
                        },
                        {
                            view: 'datatable',
                            id: 'studentTable',
                            select: true,
                            data: students,
                            columns: [
                                {
                                    id: 'student_id',
                                    hidden: true,
                                },
                                {
                                    id: 'account_id',
                                    hidden: true,
                                },
                                {
                                    id: 'first_name',
                                    header: 'First Name',
                                    width: 250,
                                },
                                {
                                    id: 'last_name',
                                    header: 'Last Name',
                                    width: 250,
                                },
                                {
                                    id: 'cell_phone',
                                    header: 'Cell Phone',
                                    width: 150,
                                    format: (row => {
                                        return formatPhone(row)
                                    })
                                },
                                {
                                    id: 'birthdate',
                                    header: 'Birth Day',
                                    width: 150,
                                },
                                {
                                    id: 'sex',
                                    header: 'Sex',
                                    width: 60,
                                },
                                {
                                    id: 'editstudent',
                                    header: '',
                                    width: 50,
                                    format: (row => {
                                        return "<div class='webix_el_button'><button class='webixtype_base'>Edit</button></div>";
                                    })
                                },
                                {
                                    id: 'enrollstudent',
                                    header: '',
                                    width: props.tokens ? 75 : 250,
                                    template: (row => {
                                        console.log('row is', row)
                                        //let state = props.tokens == 0 ? 'disabled' : ''
                                        //let title = props.tokens == 0 ? 'title="No credit card is on file.  You will need to go to the account management page to provide your credit card info before registering for classes"' : undefined
                                        //let warning = props.tokens == 0 ? 'No credit card is on file' : undefined
                                        //if (warning) return "<div>" + warning + "</div>";
                                        //return "<div class='webix_el_button'><button " + title + ' ' + state + " class='webixtype_base'>Classes</button> " + warning + "</div>";
                                        let state = row.cards.length == 0 ? 'disabled' : ''
                                        let title = row.cards.length == 0 ? 'title="No credit card is on file.  You will need to go to the account management page to provide your credit card info before registering for classes"' : undefined
                                        let warning = row.cards.length == 0 ? 'No credit card is on file' : undefined
                                        if (warning) return "<div>" + warning + "</div>";
                                        return "<div class='webix_el_button'><button " + title + ' ' + state + " class='webixtype_base'>Classes</button> " + warning + "</div>";
                                    })
                                },
                            ],
                            on: {
                                onItemClick: function (cell) {
                                    window.webix.$$('studentTable').select(cell.row)
                                    if (cell.column == 'editstudent') {
                                        let row = window.webix.$$('studentTable').getItem(cell.row)
                                        sendCommand('getStudent', { studio_id, student_id: row.student_id }).then(resp => {
                                            //let student_levels = resp.result.student_levels.map(x => x.student_level_id)
                                            //console.log('student_levels is', student_levels)
                                            newStudent(row.student_id, account_id, studio_id, () => {
                                                sendCommand('getStudentsForAccount', { account_id }).then(resp => {
                                                    let students = resp.result
                                                    window.webix.$$('newStudentForm').parse(row)
                                                    window.webix.$$('studentTable').clearAll()
                                                    window.webix.$$('studentTable').parse(students)
                                                })
                                                Object.values(window.webix.$$('newStudentForm').elements).forEach(field => {
                                                    if (field.getInputNode().onkeyup) {
                                                        field.getInputNode().onkeyup()
                                                    }
                                                })
                                            })
                                        })
                                    }
                                    if (cell.column == 'enrollstudent' && props.tokens != 0) {
                                        let row = window.webix.$$('studentTable').getItem(cell.row)
                                        studentEnrollment(row, studio_id)
                                    }
                                }
                            }
                        }
                    ]
                }
            }).show()
        })
    })
}

export async function newStudent(student_id, account_id, studio_id, cb) {

    ;['account_id', 'first_name', 'last_name', 'cell_phone', 'newstudentwindow'].forEach(el => {
        if (window.webix.$$(el)) window.webix.$$(el).destructor()
    })
    let formdata = {}
    let studentinfo = {}
    if (student_id) {
        await sendCommand('getStudent', { student_id }).then(response => {
            console.log('response is', response)
            studentinfo = response.result
        })
    }
    console.log('studentinfo', studentinfo)
    let studioinfo = {}
    if (studio_id) {
        await sendCommand('getDataForClassesForm', { studio_id }).then(data => {
            formdata = data.result
        })
    }
    let student_levels = []
    console.log('formdata')
    console.log(formdata)
    if (student_levels && student_levels.length) formdata.levels.forEach(lvl => {
        console.log('looking for ', lvl.student_level_id, 'in', student_levels)
        if (student_levels.indexOf(lvl.student_level_id) >= 0) {
            console.log('student should be part of ', lvl.student_level_id)
            lvl.checked = 1
        }
    })
    window.webix.ui({
        view: 'window',
        id: 'newstudentwindow',
        head: 'Student Info',
        close: true,
        escHide: true,
        position: 'center',
        width: 400,
        body: {
            rows: [
                window.webix.$$('accounts') ? {
                    view: 'toolbar',
                    cols: [
                        {
                            view: 'button',
                            value: 'Copy From Account',
                            width: 200,
                            click: () => {
                                let rec = window.webix.$$('accounts').getItem(window.webix.$$('accounts').getSelectedId())
                                window.webix.$$('newStudentForm').parse(rec)
                                window.webix.$$('newsex').getNode().classList.add('invalid')

                            }
                        },
                    ]
                } : { hidden: true },
                {
                    view: 'scrollview',
                    scroll: 'y',
                    height: window.innerHeight * .8,
                    width: 300,
                    body: {
                        view: 'form',
                        data: studentinfo,
                        id: 'newStudentForm',
                        elements: [
                            {
                                id: 'student_id',
                                name: 'student_id',
                                hidden: true,
                            },
                            {
                                id: 'account_id',
                                name: 'account_id',
                                hidden: true,
                            },
                            {
                                view: 'text',
                                id: 'first_name',
                                name: 'first_name',
                                label: 'First Name',
                                css: 'invalid',
                                width: 250,
                                on: {
                                    onAfterRender: function () {
                                        window.webix.$$('first_name').getInputNode().onkeyup = () => verifyField(this, 1, 'saveStudentButton')
                                    },
                                }
                            },
                            {
                                view: 'text',
                                id: 'last_name',
                                name: 'last_name',
                                label: 'Last Name',
                                width: 250,
                                css: 'invalid',
                                on: {
                                    onAfterRender: function () {
                                        window.webix.$$('last_name').getInputNode().onkeyup = () => verifyField(this, 1, 'saveStudentButton')
                                    },
                                }
                            },
                            {
                                view: 'text',
                                id: 'cell_phone',
                                name: 'cell_phone',
                                label: 'Cell Phone',
                                placeholder: 'Optional',
                                width: 250,
                                pattern: { mask: "(###) ###-####", allow: /[0-9]/g },
                            },
                            {
                                cols: [
                                    {
                                        id: 'birthdate',
                                        name: 'birthdate',
                                        label: 'Birthday',
                                        view: "datepicker",
                                        editable: true,
                                        width: 200,
                                        css: 'invalid',
                                        placeholder: 'MM/DD/YYYY',
                                        suggest: {
                                            type: "calendar",
                                            body: {
                                                blockDates: function (date) {
                                                    if (date < new Date()) return false;
                                                    return true;
                                                }
                                            }
                                        },
                                        on: {
                                            //onAfterRender: function () {
                                            //window.webix.$$('newbirthdate').getInputNode().onkeyup = () => verifyField(this, 6)
                                            //},
                                            onChange: function (val) {
                                                verifyField(this, 6)
                                                let age = getAge(val)
                                                if (document.getElementById('age')) {
                                                    document.getElementById('age').innerHTML = age + ' years old'
                                                }
                                            }
                                        }
                                    },
                                    {
                                        view: 'template',
                                        css: 'nomarginsnoheight',
                                        width: 180,
                                        borderless: true,
                                        template: '<div id="age" style="height: 25px; margin-left: 5px; margin-top: 10px;"> </div>',
                                        on: {
                                            onAfterRender: () => {
                                                if (studentinfo) {
                                                    let val = studentinfo.birthdate
                                                    let age = getAge(val)
                                                    document.getElementById('age').innerHTML = age + ' years old'
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                view: 'select',
                                options: ['M', 'F'],
                                id: 'sex',
                                name: 'sex',
                                width: 250,
                                label: 'Sex',
                                on: {
                                    onAfterRender: function () {
                                        window.webix.$$('sex').getInputNode().onchange = () => verifyField(this, 1, 'saveStudentButton')
                                    },
                                }
                            },
                            {
                                view: 'template',
                                template: '<div style="font-weight: bold;">Student Levels</div>',
                                borderless: true,
                                css: 'nomarginsnoheight',
                                hidden: ! studio_id,
                            },
                            {
                                view: 'datatable',
                                id: 'new_student_levels',
                                height: 250,
                                width: 460,
                                hidden: ! studio_id,
                                columns: [
                                    {
                                        id: 'checked',
                                        header: 'Selected',
                                        template: (rec) => {
                                            return rec.checked ? "<img src='/checked.png' width='25'/>" : ""
                                        },
                                        sort: 'string',
                                        width: 90
                                    },
                                    {
                                        id: 'student_level_id',
                                        hidden: true
                                    },
                                    {
                                        header: 'Level',
                                        id: 'level_name',
                                        fillspace: 1,
                                        sort: 'string',
                                    }
                                ],
                                data: formdata.levels,
                                on: {
                                    onItemClick: function (a, b, c) {
                                        let rec = this.getItem(a.row)
                                        rec.checked = rec.checked ? 0 : 1
                                        this.updateItem(a.row, rec)
                                    },
                                    onStructureLoad: function () {
                                        this.blockEvent()
                                        this.sort(function (a, b) {
                                            let acheck = a.checked ? 'A' : 'B'
                                            let bcheck = b.checked ? 'A' : 'B'
                                            return ((acheck + a.level_name) > (bcheck + b.level_name) ? 1 : -1)
                                        })
                                        this.unblockEvent()
                                    },
                                }
                            },
                            {
                                id: 'saveStudentButton',
                                view: 'button',
                                value: 'Save Student',
                                disabled: true,
                                width: 150,
                                click: function () {
                                    let args = window.webix.$$('newStudentForm').getValues()
                                    args.account_id = account_id
                                    args.student_id = student_id
                                    args.levels = []
                                    window.webix.$$('new_student_levels').data.each(row => {
                                        if (row.checked) {
                                            args.levels.push(row.student_level_id)
                                        }
                                    })
                                    sendCommand('saveStudent', args).then(resp => {
                                        console.log(resp)
                                        if (resp.success) {
                                            let students = resp.result
                                            window.webix.message({ type: 'success', text: 'Student created successfully' })
                                            window.webix.$$('newstudentwindow').destructor()
                                            if (cb) cb()
                                        }
                                        else {
                                            window.webix.message({ type: 'error', text: resp.err_msg })
                                        }
                                    })
                                }
                            },
                        ],
                        on: {
                            onAfterLoad: () => {
                                verifyForm('newStudentForm')
                            }
                        }
                    },
                },
            ]
        },

    }).show()
}


export function studentEnrollment(studentinfo, studio_id) {
    console.log('studentenrollment', studentinfo)
    if (window.webix.$$('enrollmentwindow')) window.webix.$$('enrollmentwindow').destructor()
    if (window.webix.$$('elgtable')) window.webix.$$('elgtable').destructor()
    sendCommand('getCreditCards', { account_id: studentinfo.account_id }).then(cards => {
        let card = undefined
        if (cards && cards.result.length) {
            card = cards.result[0]
        }
        console.log('card', card)
        sendCommand('getClassesForStudent', { ...studentinfo, studio_id }).then(currentclasses => {
            currentclasses = currentclasses.success ? currentclasses.result.classes : []
            console.log('current classes', currentclasses)
            sendCommand('getEligibleStudiosAndClassesForStudent', { ...studentinfo, studio_id }).then(classes => {
                console.log('classes eligible are', classes)
                window.webix.ui({
                    view: 'window',
                    id: 'enrollmentwindow',
                    head: 'Student Enrollment - ' + studentinfo.first_name + ' ' + studentinfo.last_name,
                    close: true,
                    escHide: true,
                    position: 'center',
                    width: window.innerWidth * .8,
                    height: window.innerHeight - 75,
                    body: {
                        rows: [
                            {
                                view: 'template',
                                template: '<span style="font-size: 15px; font-weight: bold;">Enrolled Classes</span>',
                                borderless: true,
                                height: 25,
                            },
                            {
                                view: 'datatable',
                                data: currentclasses,
                                id: 'enrolled',
                                tooltip: true,
                                columns: [
                                    {
                                        id: 'class_id',
                                        hidden: true,
                                    },
                                    {
                                        id: 'class_name',
                                        header: 'Class Name',
                                        width: 200,
                                    },
                                    {
                                        id: 'studio_location_name',
                                        header: 'Location',
                                        width: 200,
                                    },
                                    {
                                        id: 'room_name',
                                        header: 'Room',
                                    },
                                    {
                                        id: 'start_date',
                                        header: 'Start Date',
                                        template: row => {
                                            return row.override_start_date || row.studio_session_start_date
                                        }
                                    },
                                    {
                                        id: 'end_date',
                                        header: 'End Date',
                                        template: row => {
                                            return row.override_start_date || row.studio_session_start_date
                                        }
                                    },
                                    {
                                        id: 'rrule',
                                        header: 'Schedule',
                                        fillspace: 1,
                                        minWidth: 200,
                                        template: row => {
                                            console.log('rrule', row.rrule)
                                            return rruleToText(from_rrule(row.rrule))
                                        }
                                    },
                                    {
                                        id: 'start_time',
                                        header: 'Start Time',
                                    },
                                    {
                                        id: 'end_time',
                                        header: 'End Time',
                                    },
                                ]
                            },
                            {
                                view: 'toolbar',
                                cols: [
                                    {
                                        view: 'template',
                                        template: '<div style="font-size: 15px; font-weight: bold; margin-top: 5px">Available Classes</div>',
                                        borderless: true,
                                        height: 25,
                                    },
                                    card ? {
                                        view: 'button',
                                        value: 'Sign Up For Class',
                                        width: 170,
                                        click: () => {
                                            let selected = window.webix.$$('elgtable').data.serialize().filter(x => x.selected)
                                            if (!selected.length) {
                                                window.webix.message({ type: 'error', text: 'Please select a class first' })
                                                return
                                            }
                                            else {
                                                selected = selected[0]
                                            }
                                            let args = { ...selected, studio_id, ...studentinfo }
                                            sendCommand('getClassCost', args).then(resp => {
                                                console.log('cost resp', resp)
                                                let tuition_collection_day = rruleToText(RRule.fromString(resp.result.tuition_collection_dates_rrule_str))
                                                window.webix.modalbox({
                                                    title: 'Confirm Signup?',
                                                    buttons: ["Yes", "No"],
                                                    width: 500,
                                                    text: `<div>
                                                ${resp.result.studio_name} charges the following fees when registering for classes:<br/>
                                                <div style='text-align: center; margin: auto'>
                                                <div style='text-align: left>
                                                <ul style='display: inline-block;*display: inline'>
                                                <li>${resp.result.reason.map(x => {
                                                        let parts = x.split(': ')
                                                        parts[1] = formatDollar(parts[1])
                                                        return parts[0] + ': ' + parts[1]
                                                    }).join("</li><li>")}</li>
                                                </ul><br/>
                                                </div>
                                                </div>
                                                In addition, you will be charged ${formatDollar(resp.result.class_cost_installments)} ${resp.result.cost_reoccurance} for tuition.<br/>
                                                Tuition is collected on ${tuition_collection_day}<br/><br/>
                                                Do you agree to these charges?
                                            </div>`,
                                                }).then((result) => {
                                                    sendCommand('enrollStudent', args).then(resp => {
                                                        if (resp.success) {
                                                            let students = resp.result
                                                            window.webix.message({ type: 'success', text: 'Student enrolled successfully' })
                                                            console.log('selected', selected)
                                                            window.webix.$$('elgtable').remove(selected.id)
                                                            window.webix.$$('enrolled').add(selected)
                                                            //kah
                                                        }
                                                        else {
                                                            window.webix.message({ type: 'error', text: resp.err_msg })
                                                        }
                                                    })
                                                });
                                            })
                                        }
                                    } : {
                                        view: 'button',
                                        value: 'Add Credit Card',
                                        width: 150,
                                        click: () => {
                                            creditCard(studentinfo, () => { studentEnrollment(studentinfo, studio_id) })
                                        }
                                    },
                                    card ? {} : {
                                        view: 'template',
                                        borderless: true,
                                        height: 25,
                                        width: 450,
                                        template: '<font style="color:#c00; padding-top: 8px">You have no credit card on file!  You cannot sign up for classes.</font>'
                                    },
                                    {},
                                    {
                                        id: 'filtercheck',
                                        view: 'checkbox',
                                        label: 'View All Classes',
                                        labelWidth: 120,
                                        width: 160,
                                        value: 0,
                                        on: {
                                            onChange: (c) => {
                                                window.webix.$$('elgtable').data.filter(x => c ? true : x.eligible)
                                            }
                                        },
                                    }
                                ]
                            },
                            studio_id ? {
                                view: 'text',
                                hidden: true,
                                value: studio_id,
                                name: 'studio_id',
                            } : {
                                view: 'select',
                                label: 'Studio',
                                options: [],
                            },
                            {
                                view: 'datatable',
                                id: 'elgtable',
                                scroll: 'y',
                                data: classes.result.sort((a, b) => b.eligible - a.eligible),
                                tooltip: true,
                                scroll: 'xy',
                                sortable: true,
                                columns: [
                                    {
                                        id: 'selected',
                                        view: 'checkbox',
                                        header: '',
                                        template: "{common.checkbox()}",
                                        width: 30,
                                    },
                                    {
                                        id: 'enrollstudent_id',
                                        name: 'student_id',
                                        hidden: true,
                                    },
                                    {
                                        id: 'class_id',
                                        hidden: true,
                                    },
                                    {
                                        header: 'Class',
                                        id: 'class_name',
                                        width: 150,
                                        sort: 'string',
                                    },
                                    {
                                        header: 'Description',
                                        id: 'class_description',
                                        width: 150,
                                        sort: 'string',
                                    },
                                    {
                                        header: 'Location',
                                        id: 'studio_location_name',
                                        width: 150,
                                        sort: 'string',
                                    },
                                    {
                                        header: 'Dates',
                                        template: row => {
                                            return (row.override_start_date ? row.override_start_date : row.studio_session_start_date) + ' - ' + (row.override_end_date ? row.override_end_date : row.studio_session_end_date)
                                        },
                                        width: 200,
                                        sort: 'string',
                                    },
                                    {
                                        header: 'Times',
                                        template: row => {
                                            return row.start_time && row.end_time ? (formatTime(row.start_time) + ' - ' + formatTime(row.end_time)) : ''
                                        },
                                        width: 150,
                                        sort: 'string',
                                    },
                                    {
                                        id: 'rrule',
                                        header: 'Schedule',
                                        width: 250,
                                        sort: 'string',
                                        template: row => {
                                            console.log('rrule', row.rrule)
                                            return row.rrule ? rruleToText(from_rrule(row.rrule)) : ''
                                        },
                                    },
                                    {
                                        header: [{ text: 'Registration', autoheight: true, css: 'multiline' }, { text: 'Cost', autoheight: true, css: 'multiline' }],
                                        id: 'registration_cost',
                                        template: row => {
                                            return formatDollar(row.registration_cost)
                                        },
                                        sort: 'string',
                                    },
                                    {
                                        header: 'Recurring Payment',
                                        width: 150,
                                        template: row => {
                                            return formatDollar(row.class_cost_installments) + ' (' + row.cost_reoccurance + ')'
                                        },
                                        sort: 'string',
                                    },
                                    {
                                        header: 'Eligible?',
                                        id: 'eligible',
                                        template: row => {
                                            return row.eligible ? 'Yes' : '<div style="color: #c00; float: left;">No</div><div style="width: 18px; height: 18px; margin-top: 10px; margin-left: 4px; float: left;" class="questionmark"> </div>'
                                        },
                                        tooltip: row => {
                                            return row.eligible ? '' : (row.reasons || []).join('; ')

                                        },
                                        sort: 'string',
                                    },
                                    {
                                        header: ['Age Limits', '(As Of Date)'],
                                        width: 180,
                                        template: row => {
                                            if (!row.max_age && !row.min_age) return 'No Restrictions'
                                            if (row.max_age && row.min_age) return 'Ages ' + row.min_age + ' - ' + row.max_age + (row.age_cutoff_date ? (' (' + row.age_cutoff_date + ')') : '')
                                            if (row.min_age) return 'At least ' + row.min_age + '/' + row.age_cutoff_date
                                            if (row.max_age) return 'No older than ' + row.max_age + '/' + row.age_cutoff_date
                                        },
                                        sort: 'string',
                                    },
                                    {
                                        header: 'Sex Restrictions',
                                        width: 150,
                                        template: row => {
                                            if (row.sex_limited == 'M') return 'Male Only'
                                            if (row.sex_limited == 'F') return 'Female Only'
                                            return "None"
                                        },
                                        sort: 'string',
                                    },
                                    {
                                        header: 'Restricted Levels',
                                        width: 150,
                                        template: row => {
                                            return row.student_levels.length ? row.student_levels.map(x => x.level_name).join('; ') : 'None'
                                        },
                                        sort: 'string',
                                    }

                                    //reason = []
                                    //student_levels = [{id, level_name}]
                                ],
                                on: {
                                    onAfterLoad: () => {
                                        window.webix.$$('elgtable').data.filter(x => x.eligible)
                                    },
                                    onCheck: function (row, col, state) {
                                        this.eachRow(function (r) {
                                            const rec = this.getItem(r)
                                            rec.selected = row == r && rec.eligible ? state : false
                                            this.updateItem(r, rec)
                                        })
                                    },
                                    onItemClick: (rec, e) => {
                                        console.log('clicked', rec)
                                        if (rec.column == 'eligible') {
                                            window.dotooltip(e, window.webix.$$('elgtable').getItem(rec.row))
                                            return
                                        }
                                        else {
                                            if (timeouthandler) {
                                                window.clearTimeout(timeouthandler)
                                                window.tooltip.hide()
                                            }
                                        }
                                        if (rec.column == 'eligible') {
                                            return
                                        }
                                    }
                                }
                            }
                        ]
                    }
                }).show()
            })
        })
    })
}
