import { isCompositeComponentWithType } from 'react-dom/test-utils'
import { sendCommand, encrypt, decrypt, STATES} from './apis'

let tries = 0
let thisSetPermission = undefined
const PASSLEN = 6
const PHONELEN = 10
const EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
let currentForm = 'login'

function resetPass(setPermission) {
    if (window.webix.$$('logincell').config.invalid) {
        window.webix.message({type: 'error', error: 'You must provide your cell number to reset your password'})
        return
    }
    sendCommand('resetPass', {number: window.webix.$$('logincell').getValue()})
    window.webix.ui({
        view: 'window',
        id: 'resetwindow',
        head: 'Reset password',
        close: true,
        escHide: true,
        position: 'center',
        body: {
            view: 'form',
            elements: [
                {
                    view: 'template',
                    borderless: true,
                    template: 'To verify your phone number is correct, we have texted you a code.  Please enter that code here and provide your new password',
                    autoheight: true,
                },
                {
                    view: 'form',
                    elements: [
                        {
                            view: 'text',
                            label: 'Code',
                            labelWidth: 130,
                            id: 'passcode',
                            attributes: {
                                type: 'text',
                                inputmode: 'numeric',
                                autocomplete: 'one-time-code',
                                required: true,
                            },
                            on: {
                                onAfterRender: function() {
                                    window.webix.$$('passcode').focus()
                                }
                            }
                        },
                        {
                            view: "text", id: 'resetpass1', type: "password",
                            label: 'New Password',
                            labelWidth: 130,
                            css: 'invalid',
                            invalid: true,
                            on: {
                                onAfterRender: function () {
                                    window.webix.$$('resetpass1').getInputNode().onkeyup = () => verifyField(this, PASSLEN)
                                }
                            }
                        },
                        {
                            view: "text", id: 'resetpass2', type: "password",
                            label: 'Confirm Password',
                            labelWidth: 130,
                            css: 'invalid',
                            invalid: true,
                            on: {
                                onAfterRender: function () {
                                    window.webix.$$('resetpass2').getInputNode().onkeyup = () => verifyField(this, PASSLEN)
                                }
                            }
                        },
                        {
                            width: 250,
                            cols: [
                                {
                                    id: 'verifybutton',
                                    view: 'button',
                                    value: 'Reset Password',
                                    click: () => {
                                        let pass1 = window.webix.$$('resetpass1').getValue()
                                        let pass2 = window.webix.$$('resetpass2').getValue()
                                        let code = window.webix.$$('passcode').getValue()
                                        if (pass1 != pass2) {
                                            window.webix.message({type: 'error', text: 'Your passwords need to match'})
                                            return
                                        }
                                        if (! code) {
                                            window.webix.message({type: 'error', text: 'You need to provide the code we texted you'})
                                            return
                                        }
                                        sendCommand('resetPassCode', {code: code, password: pass1, number: window.webix.$$('logincell').getValue()}).then(resp => {
                                            if ( ! resp.success ) {
                                                window.webix.message({type: 'error', text: resp.error})
                                                return
                                            }
                                            if (resp.result.status == 'logged in') window.webix.message({type: 'success', text: 'Password reset sucessfully'})
                                            loggedin(resp, setPermission)
                                        })

                                    }
                                },
                                { view: 'button', value: "Back to Login", hidden: true, css: "webix_primary",
                                    click: () => {
                                        window.webix.$$('resetwindow').destructor()
                                        changeForm('login')
                                    }
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    }).show()
}

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}

function verifyField(field, check) {
    let f = field.getNode()
    console.log('check', check)
    let invalid = isInt(check) ? field.getValue().length < check : ! check.test(field.getValue())
    if ( invalid ) {
        if (!field.getNode().classList.contains('invalid')) {
            field.getNode().classList.add('invalid')
            field.config.invalid = true
            checkFields()
        }
    }
    else {
        if (field.getNode().classList.contains('invalid')) {
            field.getNode().classList.remove('invalid')
            field.config.invalid = false
            checkFields()
            if ( field.config.id == 'logincell' ) window.webix.$$('loginpass').focus()
        }
    }
}

function checkFields() {
    if (window.webix.$$('logincell').config.invalid) {
        window.webix.$$('resetpass').disable()
    }
    else {
        window.webix.$$('resetpass').enable()

    }
    if (window.webix.$$('logincell').config.invalid || window.webix.$$('loginpass').config.invalid) {
        setHotkey('createaccountbtn')
        window.webix.$$('trylogin').disable()
    }
    else {
        setHotkey('trylogin')
        window.webix.$$('trylogin').enable()

    }
    if (window.webix.$$('logincell').config.invalid || window.webix.$$('loginpass').config.invalid ||
        window.webix.$$('loginpassverify').config.invalid) {
        window.webix.$$('createaccountbtn').disable()
    }
    else {
        setHotkey('createaccountbtn')
        window.webix.$$('createaccountbtn').enable()
    }

}

function setHotkey(which) {
    window.webix.UIManager.removeHotKey('enter', null)
    window.webix.$$(which).hotkey_setter('enter')
}

async function verifyPhoneCode() {
    console.log('running verifyPhoneCode')
    if ( window.webix.$$('account_id').getValue() && !window.webix.$$('loginpassverify').config.invalid && window.webix.$$('loginpassverify').getValue() != window.webix.$$('loginpass').getValue()) {
        window.webix.message({ type: 'error', text: 'The password from the previous screen must match the password on this screen', expire: 3000 })
        return
    }
    sendCommand('verifyPhoneCode', { account_id: window.webix.$$('account_id').getValue(), code: window.webix.$$('code').getValue(), number: window.webix.$$('logincell').getValue(), password: window.webix.$$('loginpass').getValue() }).then(resp => {
        console.log('after code verification', resp)
        if ( ! resp.success ) {
            window.webix.message({type: 'error', text: resp.error, expire: 3000})
            return
        }
        if ( resp.result.status == 'success' ) {
            if ( window.webix.$$('pinwindow') ) window.webix.$$('pinwindow').destructor()
            thisSetPermission(resp.result.perms)
            window.webix.message({type: 'success', text: 'Your cell phone number has been validated'})
            window.webix.$$('login').hide()
            window.webix.$$('menu').show()
        }
    })

}

async function verifyPhone() {
    console.log('running verifyPhone')
    if (!window.webix.$$('loginpassverify').config.invalid && window.webix.$$('loginpassverify').getValue() != window.webix.$$('loginpass').getValue()) {
        window.webix.message({ type: 'error', text: 'The password from the previous screen must match the password on this screen', expire: 3000 })
        return
    }
    sendCommand('verifyPhoneSend', {
        number: window.webix.$$('logincell').getValue(),
        password: window.webix.$$('loginpass').getValue(),
        first_name: window.webix.$$('first_name').getValue(),
        last_name: window.webix.$$('last_name').getValue(),
        address1: window.webix.$$('address1').getValue(),
        address2: window.webix.$$('address2').getValue(),
        city: window.webix.$$('city').getValue(),
        state: window.webix.$$('state').getValue(),
        zip: window.webix.$$('zip').getValue(),
        email: window.webix.$$('email').getValue(),
    }).then((resp) => {
        console.log('verifyphonesend', resp)
        window.webix.$$('account_id').setValue(resp.result.account_id)
        if (!resp.success) {
            window.webix.message({type: 'error', text: 'Failed to send the code.  Please click resend.', expire: 2000})
        }
    })
    if ( window.webix.$$('pinwindow') ) window.webix.$$('pinwindow').destructor()
    window.webix.ui({
        view: 'window',
        id: 'pinwindow',
        head: 'Confirmation Code',
        close: true,
        escHide: true,
        position: 'center',
        body: {
            rows: [
                {
                    view: 'template',
                    borderless: true,
                    template: 'To verify your phone number is correct, we have texted you a code.  Please enter that code here:',
                    autoheight: true,
                },
                {
                    view: 'form',
                    elements: [
                        {
                            view: 'text',
                            label: 'Code',
                            id: 'code',
                            width: 200,
                            labelWidth: 80,
                            attributes: {
                                type: 'text',
                                inputmode: 'numeric',
                                autocomplete: 'one-time-code',
                                required: true,
                            },
                            on: {
                                onAfterRender: function() {
                                    console.log('this is', this)
                                    window.webix.$$('code').focus()
                                }
                            }
                        },
                        {
                            width: 250,
                            cols: [
                                {
                                    id: 'verifybutton',
                                    view: 'button',
                                    value: 'Submit',
                                    click: () => { console.log('calling 3'); verifyPhoneCode() },
                                },
                                {
                                    view: 'button',
                                    value: 'Resend Code',
                                    click: () => { console.log('calling 2'); verifyPhone() },
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    }).show()
}

function loggedin(resp, setPermission) {
    if (!resp.success) {
        window.webix.message({ type: 'error', text: resp.error, expire: 2000 })
    }
    else {
        if (resp.result.status == 'new user') {
            console.log('new user')
            changeForm('verifypw')
            //verifyWindow()
        }
        if (resp.result.status == 'not validated') {
            window.webix.message('Your cell phone number has not been verified yet.  Please validate your phone number to log in')
            verifyPhone()
        }
        if (resp.result.status == 'logged in') {
            localStorage.setItem("info", encrypt(resp.result.uuid))
            setPermission(resp.result.perms)
            if ( window.webix.$$('resetwindow') ) {
                window.webix.$$('resetwindow').destructor()
            }
            window.webix.$$('login').hide()
            window.webix.$$('menu').show()
        }
    }
}

async function doLogin(setPermission) {
    let logincell = window.webix.$$('logincell').getValue()
    let loginpass = window.webix.$$('loginpass').getValue()
    await sendCommand('checkUser', { cell_phone: logincell.replace(/\D/g, ''), password: loginpass }).then((resp) => {
        console.log('checkUser results', resp)
        loggedin(resp, setPermission)
    })
}


function changeForm(which) {
    let views = window.webix.ui.views
    Object.keys(views).filter(el => views[el].config.onForm).forEach(name => {
        if (views[name].config.onForm.includes(which)) {
            views[name].show()

        }
        else {
            views[name].hide()
        }
    })
}

export const login = (props) => {
    thisSetPermission = props.setPermission
    let info = localStorage.getItem("info")
    let needpage = true
    if (info) {
        sendCommand('authByUUID', {info: decrypt(info)}).then(resp => {
            console.log('here is what I got', resp)
            if (resp.result.status == 'logged in') {
                loggedin(resp, thisSetPermission)
                needpage = false
            }
        })

    }
    if (! needpage ) return {}
    return {
        id: 'login',
        view: 'align',
        width: 300,
        align: 'middle,center',
        body: {
            width: 300,
            view: 'form',
            id: 'loginform',
            elements: [
                { id: 'prompt', onForm: 'login', view: 'template', borderless: true, template: 'Login or Create Account', css: 'loginprompt', autoheight: true },
                { id: 'promptverify', onForm: 'verifypw', hidden: true, view: 'template', borderless: true, template: 'This seems like a new number to us.  Please confirm it is correct and, if it is correct, please retype your password and fill in these fields so we can verify your number and create your account.', css: 'loginprompt', autoheight: true },
                {
                    view: 'text',
                    id: 'account_id',
                    hidden: true,
                },
                {
                    view: "text",
                    id: 'logincell',
                    name: 'logincell',
                    placeholder: "Cell Number",
                    css: 'invalid',
                    onForm: 'login verifypw',
                    invalid: true,
                    pattern: { mask: "(###) ###-####", allow: /[0-9]/g },
                    on: {
                        onAfterRender: function () {
                            this.focus()
                            window.webix.$$('logincell').getInputNode().onkeyup = () => verifyField(this, PHONELEN)
                        },
                    }
                },
                {
                    view: "text",
                    id: 'loginpass',
                    name: 'loginpass',
                    type: "password",
                    placeholder: "Password",
                    css: 'invalid',
                    onForm: 'login',
                    invalid: true,
                    on: {
                        onAfterRender: function () {
                            window.webix.$$('loginpass').getInputNode().onkeyup = () => verifyField(this, PASSLEN)
                        }
                    }
                },
                {
                    view: "text",
                    hidden: true,
                    id: 'loginpassverify',
                    name: 'loginpassverify',
                    type: "password",
                    placeholder: "Confirm Password",
                    invalid: true,
                    css: 'invalid',
                    onForm: 'verifypw',
                    on: {
                        onAfterRender: function () {
                            window.webix.$$('loginpassverify').getInputNode().onkeyup = () => verifyField(this, PASSLEN)
                        },
                    },
                },
                {
                    view: "text",
                    hidden: true,
                    id: 'first_name',
                    name: 'first_name',
                    placeholder: "First Name",
                    onForm: 'verifypw',
                    invalid: true,
                    css: 'invalid',
                    on: {
                        onAfterRender: function () {
                            window.webix.$$('first_name').getInputNode().onkeyup = () => verifyField(this, 1)
                        },
                    },
                },
                {
                    view: "text",
                    hidden: true,
                    id: 'last_name',
                    name: 'last_name',
                    placeholder: "Last Name",
                    onForm: 'verifypw',
                    css: 'invalid',
                    invalid: true,
                    on: {
                        onAfterRender: function () {
                            window.webix.$$('last_name').getInputNode().onkeyup = () => verifyField(this, 1)
                        },
                    },
                },
                {
                    view: "text",
                    hidden: true,
                    id: 'address1',
                    name: 'address1',
                    placeholder: "Address Line 1",
                    onForm: 'verifypw',
                    invalid: true,
                    css: 'invalid',
                    on: {
                        onAfterRender: function () {
                            window.webix.$$('address1').getInputNode().onkeyup = () => verifyField(this, 1)
                        },
                    },
                },
                {
                    view: "text",
                    hidden: true,
                    id: 'address2',
                    name: 'address2',
                    placeholder: "Address Line 2",
                    onForm: 'verifypw',
                },
                {
                    view: "text",
                    hidden: true,
                    id: 'city',
                    name: 'city',
                    placeholder: "City",
                    onForm: 'verifypw',
                    invalid: true,
                    css: 'invalid',
                    on: {
                        onAfterRender: function () {
                            window.webix.$$('city').getInputNode().onkeyup = () => verifyField(this, 1)
                        },
                    },
                },
                {
                    view: 'combo',
                    hidden: true,
                    id: 'state',
                    name: 'state',
                    placeholder: "State",
                    onForm: 'verifypw',
                    options: STATES,
                },
                {
                    view: "text",
                    hidden: true,
                    id: 'zip',
                    name: 'zip',
                    placeholder: "Zip Code",
                    onForm: 'verifypw',
                    invalid: true,
                    css: 'invalid',
                    on: {
                        onAfterRender: function () {
                            window.webix.$$('zip').getInputNode().onkeyup = () => verifyField(this, /\d{5}/)
                        },
                    },
                },
                {
                    view: "text",
                    hidden: true,
                    id: 'email',
                    name: 'email',
                    placeholder: "Email Address",
                    invalid: true,
                    css: 'invalid',
                    onForm: 'verifypw',
                    on: {
                        onAfterRender: function () {
                            window.webix.$$('email').getInputNode().onkeyup = () => verifyField(this, EMAIL)
                        },
                    },
                },
                {
                    cols: [
                        { view: 'button', onForm: 'verifypw', id: 'createaccountbtn', value: "Verify Cell #", hidden: true, hotkey: 'enter', css: "webix_primary", click: () => { console.log('calling 1'); verifyPhone() } },
                        { view: 'button', onForm: 'verifypw', id: 'loginback', value: "Back to Login", hidden: true, css: "webix_primary",
                            click: () => {
                                changeForm('login')
                                //backToLogin(props.setPermission)
                            }
                        },
                        { view: 'button', onForm: 'login', id: 'trylogin', disabled: true, value: "Login", css: "webix_primary", click: () => { doLogin(props.setPermission) } },
                        { view: 'button', onForm: 'login', id: 'resetpass', disabled: true, value: "Reset Password", css: "webix_primary", click: () => { resetPass(props.setPermission) } },
                    ]
                }
            ],
        }
    }
}