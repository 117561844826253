import { datetime, RRule, RRuleSet, rrulestr } from 'rrule'

export function from_rrule(rrule) {
    return rrulestr(rrule)
}

export function calculate_rrule(start, end, freq, interval, weekdays, relativeDay, bymonthday, passrrule) {
    // start/end have to be date objects
    // relativeDay = First, Second, Third, Fourth, or Last
    // bymonthday = [days of month]
    // interval = interval of freq: every _ <freq>
    console.log('rrule got this', { start, end, freq, interval, weekdays, relativeDay, bymonthday })
    console.log('start', start)
    console.log('end', end)
    console.log('freq', freq)
    console.log('interval', interval)
    console.log('weekdays', weekdays)
    console.log('relativeDay', relativeDay)
    console.log('bymonthday', bymonthday)

    freq = RRule[freq.toUpperCase()]
    let rrule = { freq, dtstart: start, until: end }
    if (interval) rrule.interval = interval
    if (bymonthday && bymonthday.length) rrule.bymonthday = bymonthday
    let byweekday = []
    weekdays.forEach(x => {
        console.log('trying to figure out what ' + x + ' is')
        switch (x) {
            case 'Weekday':
                console.log('got a weekday')
                byweekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
                break

            case 'Weekend':
                console.log('got a weekend')
                byweekday = [RRule.SA, RRule.SU]
                break

            default:
                console.log('defaulted here', x)
                byweekday.push(RRule[x.toUpperCase()])
        }
    })
    console.log('byweekday is', [...byweekday])
    if (byweekday.length && !(byweekday.length == 1 && byweekday[0] == undefined)) rrule.byweekday = byweekday

    let bysetpos = []
    if (relativeDay.length) {
        relativeDay.forEach(d => {
            bysetpos.push({
                'first': 1,
                'second': 2,
                'third': 3,
                'fourth': 4,
                'last': -1,
            }[d.toLowerCase()])
        })
    }
    if (bysetpos.indexOf(-1) >= 0 && weekdays == 'Day') {
        rrule.bymonthday = -1
        //FREQ=MONTHLY;BYMONTHDAY=-1
    }
    else {
        //FREQ=Monthly;BYDAY=Th;BYSETPOS=-1
        //FREQ=Monthly;BYDAY=Th;BYSETPOS=1,3
        if (bysetpos.length) rrule.bysetpos = bysetpos //.join(',')
    }

    console.log('creating rrule using', rrule)
    const rule = new RRule(rrule)
    console.log('rule text: ', rule.toText())
    console.log('rule: ', rule.toString())
    console.log('back: ', from_rrule(rule.toString()))
    if (passrrule) return rule
    return rule.toString()
}

export const rruleToText = (rrule) => {
    if (!rrule) return ''
    console.log('rrule obj', rrule)
    let words = {
        '-1': 'last',
        '1': 'first',
        '2': 'second',
        '3': 'third',
        '4': 'fourth',
    }
    let dows = {
        '0': 'Monday',
        '1': 'Tuesday',
        '2': 'Wednesday',
        '3': 'Thursday',
        '4': 'Friday',
        '5': 'Saturday',
        '6': 'Sunday',
    }
    let text = rrule.toText()
    text = text.split(' until ')[0]
    let freq = rrule.origOptions.freq
    if (freq == 1) { 
        //montly
        let bymonthday = rrule.origOptions.bymonthday && rrule.origOptions.bymonthday.length ? rrule.origOptions.bymonthday[0] : undefined
        if (!bymonthday) {
            bymonthday = ('bymonthday' in rrule.origOptions) ? rrule.origOptions.bymonthday : undefined
        }
        let bysetpos = typeof (rrule.origOptions.bysetpos) == 'string' || typeof (rrule.origOptions.bysetpos) == 'number' ? rrule.origOptions.bysetpos : rrule.origOptions.bysetpos && rrule.origOptions.bysetpos.length ? rrule.origOptions.bysetpos[0] : undefined
        let word = words[String(bysetpos)]
        let byweekday = rrule.options.byweekday && rrule.options.byweekday.length ? rrule.options.byweekday[0] : undefined
        let dow = dows[String(byweekday)]
        dow = dow ? dow : 'day'

        console.log('rrule', rrule)
        console.log('bymonthday', bymonthday)
        console.log('bysetpos', bysetpos)
        console.log('byweekday', byweekday)
        console.log('word', word)
        console.log('dow', dow)
        console.log('orig text', text)
        if (bysetpos && !bymonthday) {
            if (dow) {
                console.log('0')
                return 'The ' + word + ' ' + dow + ' of every month'

            }
            console.log('1')
            return 'The ' + word + ' day of every month'
        }
        if (bymonthday == -1) {
            return 'The last day of every month'
        }
        if (bysetpos == -1 && byweekday >= 0) {
            console.log('3')
            text = 'The last ' + dow + ' of every month'
        }
        else {
            console.log('4')
            let ord = text.split(' ').pop()
            if (bymonthday > 28) {
                ord = ord + ' (or the last)'
            }
            text = 'On the ' + ord + ' day of the month'
        }
    }
    if (freq == 2) { 
        // weekly
        //text = rrule.toText()
    }
    console.log(text)
    return text
}
