import {sendCommand} from '../apis'
import {buildEditors, wording} from '../custom_components'
import { RRule } from 'rrule'

export function manageStudioPaymentOptions(studioinfo) {
    buildEditors()
    if (window.webix.$$('studiopaymentoption_window')) window.webix.$$('studiopaymentoption_window').destructor()
    sendCommand('getStudioPreferences', studioinfo).then(resp => {
        let data = {}
        // tution processing date (day of month, for example)
        // what's charged on first payment
        // when is registration charged? (at signup, first day of class, deadline date, N days or less before start, etc)
        // square account info for direct deposit
        let prefs = resp.result
        if (prefs.length) {
            let rruleStr = prefs[0].tuition_processing_rrule
            console.log('here is rrule', rruleStr)
            let rrule = RRule.fromString(rruleStr)
            console.log(rrule)
            prefs[0].tuition_processing_date = window.enhancedRRuleText(rrule)
        }
        else {
            let rruleStr = 'RRULE:FREQ=MONTHLY;BYMONTHDAY=1'
            let rrule = RRule.fromString(rruleStr)
            prefs.push({
                'studio_id': studioinfo.studio_id,
                'tuition_processing_rrule': rruleStr,
                'tuition_processing_date': window.enhancedRRuleText(rrule),
                'registration_processing_day': 'atregistration',
                'whats_due_at_registration': ['whatsdueatregistration_registration_fee'],
                'verify_before_registration': false
            })
        }
        let mappings = wording
        console.log(prefs)
        console.log('whats_due type', typeof(prefs[0].whats_due_at_registration))
        if ( typeof(prefs[0].whats_due_at_registration) == 'string' ) {
            prefs[0].whats_due_at_registration = [prefs[0].whats_due_at_registration]

        }
        prefs[0].whats_due_at_registration_disp = prefs[0].whats_due_at_registration.map(x => `<span style='background-color: #fff;'>` + mappings[x] + `</span>`).join(' + ')
        Object.keys(prefs[0]).forEach(key => {
            data[key] = prefs[0][key]
        })
        console.log('data', data)
        window.webix.ui({
            view: 'window',
            id: 'studiopaymentoption_window',
            head: 'Manage Studio Settings',
            close: true,
            escHide: true,
            position: 'center',
            scroll: 'auto',
            width: 795,  //TODO: make this 300 again
            height: 400,
            body: {
                rows: [
                    {
                        view: 'toolbar',
                        cols: [
                            {
                                view: 'button',
                                value: 'Save Changes',
                                click: () => {
                                    sendCommand('saveStudioPreferences', data).then(resp => {
                                        if (resp.success) {
                                            window.webix.message({type: 'success', text: 'Successfully saved'})
                                        }
                                        else {
                                            window.webix.message({ type: 'error', text: resp.err_msg })
                                        }
                                    })
                                    console.log("saving", data)
                                },
                            },
                            {
                                view: 'template',
                                template: 'Click any value on the right to change the value',
                                borderless: true,
                            }
                        ]
                    },
                    {
                        view:"property",
                        id: 'studionpaymentoptiontable',
                        width: 795,
                        nameWidth: 250,
                        elements:[
                            { id: 'verify_before_registration', label: 'Staff Must Verify Registrations', type: 'checkbox' },
                            { id: 'registration_processing_day', label: 'Registration Collection Date', type: 'select', options: [
                                { id: 'atregistration', value: data.verify_before_registration ? 'Same Day As Verification' : 'When Registering'},
                                { id: 'atdeadline', value: 'On Session Deadline Date' },
                                { id: 'firstdayofclass', value: 'First Day Of Class' },
                            ] }, // popup:"rrule" },
                            { id: 'whats_due_at_registration_disp', label: "What's Due At Registration", type:"whatsdueatregistration", _value: prefs[0].whats_due_at_registration },
                            { id: 'tuition_processing_date', label: 'Tuition Collection Date', rrule: prefs[0].tuition_processing_rrule, type: 'rrule_tuition', width: 700 },
                        ],
                        scroll: 'auto',
                        data: data,
                        on: {
                            onCheck: function(field, value) {
                                if (field == 'verify_before_registration') {
                                    data.verify_before_registration = value
                                    window.webix.$$('studionpaymentoptiontable').config.elements[1].collection.updateItem('atregistration', {value: value ? 'Same Day As Verification' : 'When Registering'})
                                }
                            },
                            onAfterEditStop: function (value, e, ignore) {
                                if (e.id == 'registration_processing_day') {
                                    data.registration_processing_day = value.value
                                }
                                if (e.id == 'whats_due_at_registration_disp') {
                                    data.whats_due_at_registration = e.config._value
                                }
                                if (e.id == 'tuition_processing_date') {
                                    data.tuition_processing_rrule = e.config.rrule

                                }
                                console.log('done editing')
                                console.log(data)
                                //alert('done editing')
                            },
                            blah: function (value, e, ignore) {
                                if (ignore || ! window.webix.$$('studionpaymentoptiontable'))  return
                                if (value.value != value.old) {
                                    let rec = window.webix.$$('studionpaymentoptiontable').getItem(e.row)
                                    console.log('update', rec)
                                    if ( rec.studio_session_deadline_date && rec.studio_session_deadline_date.toISOString ) rec.studio_session_deadline_date = rec.studio_session_deadline_date.toISOString().split('T')[0]
                                    if ( rec.studio_session_start_date && rec.studio_session_start_date.toISOString ) rec.studio_session_start_date = rec.studio_session_start_date.toISOString().split('T')[0]
                                    if ( rec.studio_session_end_date && rec.studio_session_end_date.toISOString ) rec.studio_session_end_date = rec.studio_session_end_date.toISOString().split('T')[0]
                                    sendCommand('saveStudioSession', rec).then(resp => {
                                        console.log('resp', resp)
                                        if (resp.success) {
                                            window.webix.message({ type: 'success', text: 'Successfully Changed' })
                                            window.webix.$$('studionpaymentoptiontable').updateItem(rec.id, resp.result[0])
                                            //window.webix.$$('studionsessiontable').parse(resp.result)
                                        }
                                        else {
                                            window.webix.message({ type: 'error', text: resp.err_msg })
                                        }
                                    })
                                }
                            }
                        },
                    }
                ]
            },
        }).show()
    })
}