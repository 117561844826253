import {sendCommand, formatPhone} from '../apis'
import {findPerson} from '../findPerson'

export function manageAdmins(manage_studio_id) {
    if (window.webix.$$('studio_admins')) window.webix.$$('studio_admins').destructor()
    let owners = []
    sendCommand('getAdminsForStudio', { studio_id: manage_studio_id }).then(resp => {
        console.log('getAdmins', resp)
        owners = resp.result.admins
        console.log('owners', owners)
        window.webix.ui({
            view: 'window',
            id: 'studio_admins',
            head: 'Studio Owners and Admins',
            close: true,
            escHide: true,
            position: 'center',
            width: 500,
            height: 500,
            body: {
                rows: [
                    {
                        view: 'toolbar',
                        height: 30,
                        elements: [
                            {
                                view: 'button',
                                value: 'Add Owner/Administrator',
                                click: function () {
                                    findPerson(undefined, 'account', (selected => {
                                        console.log('selected', selected)
                                        let account_ids = window.webix.$$('owners').collectValues('account_id').map(x => x.value)
                                        console.log('account_ids', account_ids)
                                        window.webix.$$('owners').parse(selected.filter(row => {
                                            return account_ids.indexOf(row.account_id) < 0
                                        }))
                                        selected.forEach(row => {
                                            sendCommand('addOwner', { ...row, studio_id: manage_studio_id }).then(results => {
                                                if (results.success) {
                                                    window.webix.message({ type: 'success', text: 'Successfully Added Owner' })
                                                }
                                                else {
                                                    window.webix.message({ type: 'error', text: results.err_msg })
                                                }
                                            })
                                        })
                                    }))
                                }
                            },
                            {
                                view: 'button',
                                value: 'Delete Manager',
                                click: function () {
                                    window.webix.$$('owners').data.each(row => {
                                        if (row.selected) {
                                            window.webix.$$('owners').remove(row.id)
                                            sendCommand('deleteOwner', { ...row, studio_id: manage_studio_id }).then(results => {
                                                if (results.success) {
                                                    window.webix.message({ type: 'success', text: 'Successfully Deleted Owner' })
                                                }
                                                else {
                                                    window.webix.message({ type: 'error', text: results.err_msg })
                                                }
                                            })
                                        }
                                    })
                                }
                            },
                        ]
                    },
                    {
                        view: 'datatable',
                        width: 600,
                        select: true,
                        id: 'owners',
                        data: owners,
                        columns: [
                            {
                                id: 'account_id',
                                hidden: true,
                            },
                            {
                                id: 'selected',
                                width: 30,
                                header: '',
                                template: '{common.radio()}'
                            },
                            {
                                id: 'first_name',
                                width: 120,
                                header: 'First Name',
                            },
                            {
                                id: 'last_name',
                                width: 120,
                                header: 'Last Name',
                            },
                            {
                                id: 'cell_phone',
                                header: 'Cell Phone',
                                template: function (row) {
                                    return formatPhone(row.cell_phone)
                                }
                            },
                            {
                                id: 'is_primary_owner',
                                header: 'Primary Owner?',
                                width: 120,
                                template: function (row) {
                                    return row.is_primary_owner ? '<input type="checkbox" checked/>' : '<input type="checkbox" />'
                                }
                            },
                        ],
                        on: {
                            onItemClick: function (cell) {
                                console.log(cell)
                                if (cell.column == 'is_primary_owner') {
                                    let rec = this.getItem(cell.row)
                                    console.log('updating admin:', cell, rec)
                                    this.updateItem(cell.row, { is_primary_owner: !rec.is_primary_owner })
                                    if (rec.is_primary_owner) {
                                        owners.forEach(owner => {
                                            if ( owner.account_id != rec.account_id ) {
                                                owner.is_primary_owner = 0
                                                window.webix.$$('owners').updateItem(owner.id, owner)
                                            }
                                        })
                                    }
                                    sendCommand('addOwner', { ...rec, studio_id: manage_studio_id }).then(results => {
                                        if (results.success) {
                                            window.webix.message({ type: 'success', text: 'Successfully changed' })
                                        }
                                        else {
                                            window.webix.message({ type: 'error', text: results.err_msg })
                                        }
                                    })
                                }
                            },
                            onAfterRender: function () {
                                this.adjustColumn('cell_phone', 'all')
                                this.adjustColumn('first_name', 'all')
                                this.adjustColumn('last_name', 'all')
                            }
                        }
                    }
                ]
            }
        }).show()
    })
}
