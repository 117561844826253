import CryptoJS from "crypto-js"
import { type } from "webix"
import {v4 as uuidv4} from 'uuid'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { wait } from "@testing-library/user-event/dist/utils"

var moment = require('moment')
 
window.socket = new ReconnectingWebSocket('wss://studiodirector.pro:28001')

window.socket.onmessage = function(event) {
    let data = JSON.parse(event.data)
    console.log('ws response', data)
    if ( data.socketuuid ) {
        console.log(data.socketuuid)
        window.received[data.socketuuid] = data
    }
}


async function connection(timeout = 10000) {
  const isOpened = () => (window.socket.readyState === WebSocket.OPEN)

  if (window.socket.readyState !== WebSocket.CONNECTING) {
    return isOpened()
  }
  else {
    const intrasleep = 100
    const ttl = timeout / intrasleep // time to loop
    let loop = 0
    while (window.socket.readyState === WebSocket.CONNECTING && loop < ttl) {
      await new Promise(resolve => setTimeout(resolve, intrasleep))
      loop++
    }
    return isOpened()
  }
}

const opened = await connection()
if (! opened) {
  console.log("the socket is closed OR couldn't have the socket in time, program crashed");
}

window.resolves = {}
window.rejects = {}
window.received = {}

export async function sendCommand(command, args) {
    console.log('executing API command', command, args)
    return new Promise(async (resolve, reject) =>{
        let timeout = 10000
        args.auth = localStorage.getItem("info")
        let socketuuid = uuidv4()
        window.resolves[socketuuid] = command
        window.rejects[socketuuid] = reject
        window.socket.send(JSON.stringify({command, args, socketuuid}))
        const intrasleep = 100
        const ttl = timeout / intrasleep // time to loop
        let loop = 0
        while (!window.received[socketuuid] && loop < ttl) {
            await new Promise(resolve => setTimeout(resolve, intrasleep))
            loop++
        }
        if (window.received[socketuuid]) {
            let resp = window.received[socketuuid]
            delete window.received[socketuuid]
            resolve(resp.response)
        }
        reject()

    })
}

export async function old_sendCommand(command, args) {
    return new Promise((resolve, reject) => {
        if (!window.socket) {
            reject('No socket connection.');
        }
        else {
            window.socket.send(command, args, (response) => {
                //window.socket.send(JSON.stringify({command, args}))
                if (response.error) {
                    reject(response.error);
                }
                else {
                    resolve(response);
                }
            });
        }
    });
}


const secretPass = "34Xk53SVdfan43FcadfWWWWNnsafd"

const DEBUG = 1

/*
export async function sendCommand(command, args) {
    DEBUG && console.log('executing command', command, args)
    args.command = command
    return await fetch('https://studiodirector.pro/cgi-bin/studiodir.py', {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(args)
    }).then(resp => {
        return resp.json()
    })
}
*/

export function waitForEl(webixElement) {
        //if (el.getInputNode() && el.getInputNode().dispatchEvent) el.getInputNode().dispatchEvent(new Event('keyup'))
    return new Promise(resolve => {
        if (webixElement.getInputNode()) return true

        const observer = new MutationObserver(mutations => {
            if (webixElement.getInputNode()) {
                observer.disconnect();
                resolve(true)
            }
        });

        // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

export function formatPhone(entry) {
    if (!entry) return ''
    let match = entry.replace(/\D+/g, '').match(/([^\d]*\d[^\d]*){1,10}$/)[0]
    let part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match
    let part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
    let part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''
    return `${part1}${part2}${part3}`
}

export const formatTime = (time) => {
    if (! time || time.indexOf(':') < 0) return time
    let hours = parseInt(time.split(':')[0])
    let minutes = time.split(':')[1]
    if (hours > 12) return (hours-12) + ':' + minutes + 'PM'
    return hours + ':' + minutes + 'AM'
}

export const formatDollar = (val) => {
    if (! val && ! String(val)) return val
    val = String(val).replace(/[^0-9\.]*/g, '')
    let dollars = val.split('.')[0]
    let cents = val.indexOf('.') >= 0 ? val.split('.')[1] : '00'
    if (cents.length > 2) {
        cents = cents.substring(0,2)
    }
    while (cents.length < 2) cents = cents + '0'
    return '$' + dollars + '.' + cents
}

const _formatNumber = (val) => {
    if (! val) return val
    return parseInt(String(val).replace(/[^0-9]*/g, ''))
}

export const formatDollarEditor = {
    parse: (val) => { return formatDollar(val) },
    edit: (val) => { return formatDollar(val) },
}

export const formatNumberEditor = {
    parse: (val) => { return _formatNumber(val) },
    edit: (val) => { return _formatNumber(val) },
}

export function encrypt(s) {
    return CryptoJS.AES.encrypt(
        JSON.stringify(s),
        secretPass
    ).toString()
}

export function decrypt(s) {
    const bytes = CryptoJS.AES.decrypt(s, secretPass)
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}

function isInt(value) {
    return !isNaN(value) && (function (x) { return (x | 0) === x; })(parseFloat(value))
}

export function verifyForm(formName) {
    // run this after parsing/loading a form with invalid items
    Object.values(window.webix.$$(formName).elements).forEach(async el => {
        await waitForEl(el)
        if (el.getInputNode() && el.getInputNode().dispatchEvent) el.getInputNode().dispatchEvent(new Event('keyup'))
    })
}
export function verifyField(field, check, buttonNameOrcheckFieldsFn) {
    let invalid = true
    let value = field.getValue ? field.getValue() : field.value
    let f = field.getNode()
    if (!value) invalid = true
    else {
        if (isInt(check)) {
            invalid = value.length < check
        }
        else {
            if (check.test) {
                invalid = !check.test(value)
            }
            else {
                window.webix.message(check)
                invalid = !value.length
            }
        }
    }
    if (invalid) {
        if (!f.classList.contains('invalid')) {
            f.classList.add('invalid')
            field.config.invalid = true
            if (typeof (buttonNameOrcheckFieldsFn) === 'string') {
                window.webix.$$(buttonNameOrcheckFieldsFn).disable()
            }
            else {
                buttonNameOrcheckFieldsFn()
            }
        }
    }
    else {
        if (f.classList.contains('invalid')) {
            f.classList.remove('invalid')
            field.config.invalid = false
            if (buttonNameOrcheckFieldsFn) {
                if (typeof (buttonNameOrcheckFieldsFn) === 'string') {
                    Object.values(field.getFormView().elements).forEach(field => {
                        invalid = invalid || (('invalid' in field.config) ? field.config.invalid : false)
                    })
                    if (invalid) {
                        window.webix.$$(buttonNameOrcheckFieldsFn).disable()
                    }
                    else {
                        window.webix.$$(buttonNameOrcheckFieldsFn).enable()
                    }
                }
                else {
                    buttonNameOrcheckFieldsFn()
                }
            }
        }
    }
}

export const createDate = (y,m,d) => {
    //let date = moment(y + '-' + m + '-' + d)
    let date = new Date(y,m-1,d)
    let userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
}

export const getAge = (dateString, asOfDate) => {
    var today = asOfDate ? new Date(asOfDate) : new Date()
    var birthDate = new Date(dateString)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}

window.getAge = getAge

export const STATES = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']