import { sendCommand, STATES, verifyField, formatPhone, verifyForm } from '../apis'

export function manageRooms(row, cb) {
    if (window.webix.$$('new_room_window')) window.webix.$$('new_room_window').destructor()
    sendCommand('getStudioLocationRooms', row).then(resp => {
        let rooms = resp.result
        window.webix.ui({
            view: 'window',
            id: 'new_room_window',
            head: 'Studio Location Rooms - ' + row.studio_location_name,
            close: true,
            escHide: true,
            position: 'center',
            width: 600,  //TODO: make this 300 again
            height: 400,
            body: {
                rows: [
                    {
                        view: 'toolbar',
                        height: 30,
                        cols: [
                            {
                                view: 'button',
                                value: 'Add Room',
                                click: function () {
                                    window.webix.$$('studioroomstable').parse({ studio_id: row.studio_id, studio_location_id: row.studio_location_id })
                                    let id = window.webix.$$('studioroomstable').getLastId()
                                    window.webix.$$('studioroomstable').editCell(id, 'room_name', false, true)
                                }
                            },
                            {
                                view: 'button',
                                value: 'Delete Room',
                                click: async function () {
                                    let deleted = false
                                    await window.webix.$$('studioroomstable').data.each(thisrow => {
                                        if ( thisrow.selected ) {
                                            sendCommand('deleteStudioLocationRoom', {...row, room_id: thisrow.room_id}).then(resp => {
                                                if (resp.success) {
                                                    sendCommand('getStudioLocationRooms', row).then(resp => {
                                                        console.log('getting rooms back', resp)
                                                        window.webix.$$('studioroomstable').clearAll()
                                                        window.webix.$$('studioroomstable').parse(resp.result)
                                                        if (cb) cb()
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            },
                        ]
                    },
                    {
                        view: 'datatable',
                        editable: true,
                        scroll: 'auto',
                        data: rooms,
                        sort: true,
                        id: 'studioroomstable',
                        columns: [
                            {
                                id: 'selected',
                                width: 30,
                                header: '',
                                template: '{common.checkbox()}'
                            },
                            {
                                id: 'studio_location_id',
                                hidden: true,
                            },
                            {
                                id: 'room_id',
                                hidden: true,
                            },
                            {
                                id: 'room_name',
                                header: 'Room Name',
                                width: 270,
                                sort: 'string',
                                editor: 'text',
                            },
                        ],
                        on: {
                            onAfterEditStop: function (value, e, ignore) {
                                if (ignore || ! window.webix.$$('studioroomstable'))  return
                                if (value.value && value.value != value.old) {
                                    let rec = window.webix.$$('studioroomstable').getItem(e.row)
                                    console.log('update', rec)
                                    sendCommand('saveStudioLocationRoom', rec).then(resp => {
                                        console.log('resp', resp)
                                        if (resp.success) {
                                            window.webix.message({ type: 'success', text: 'Successfully Changed' })
                                            window.webix.$$('studioroomstable').clearAll()
                                            window.webix.$$('studioroomstable').parse(resp.result)
                                            if (cb) cb()
                                        }
                                        else {
                                            window.webix.message({ type: 'error', text: resp.err_msg })
                                        }
                                    })
                                }
                            }
                        },
                    }
                ]
            },
            on: {
                onHide: () => {
                    sendCommand('getStudioLocations', {studio_id: row.studio_id}).then(resp => {
                        let locations = resp.result
                        window.webix.$$('studiolocationstable').clearAll()
                        window.webix.$$('studiolocationstable').parse(locations)
                    })
                }
            }
        }).show()
    })
}

function addStudio(studio_id, studio_location_id, cb) {
    if (window.webix.$$('newstudiolocationwindow')) window.webix.$$('newstudiolocationwindow').destructor()
    window.webix.ui({
        view: 'window',
        id: 'newstudiolocationwindow',
        head: 'Studio Location',
        close: true,
        escHide: true,
        position: 'center',
        width: 500,
        body: {
            view: 'scrollview',
            scroll: 'auto',
            height: 370,
            width: window.innerWidth * .9,
            body: {
                view: 'form',
                width: 500,
                id: 'newStudioLocationForm',
                elements: [
                    {
                        id: 'studio_id',
                        name: 'studio_id',
                        hidden: true,
                    },
                    {
                        id: 'studio_location_id',
                        name: 'studio_location_id',
                        hidden: true,
                    },
                    {
                        view: 'text',
                        id: 'newstudio_location_name',
                        name: 'studio_location_name',
                        label: 'Location Name',
                        css: 'invalid',
                        width: 460,
                        labelWidth: 150,
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('newstudio_location_name').getInputNode().onkeyup = () => verifyField(this, 1, 'saveStudioLocationButton')
                            },
                        }
                    },
                    {
                        view: 'text',
                        id: 'newstudio_location_address1',
                        label: 'Address1',
                        name: 'studio_location_address1',
                        css: 'invalid',
                        width: 460,
                        labelWidth: 150,
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('newstudio_location_address1').getInputNode().onkeyup = () => verifyField(this, 1, 'saveStudioLocationButton')
                            },
                        }
                    },
                    {
                        view: 'text',
                        id: 'newstudio_location_address2',
                        label: 'Address2',
                        name: 'studio_location_address2',
                        width: 460,
                        labelWidth: 150,
                    },
                    {
                        view: 'text',
                        id: 'newstudio_location_city',
                        label: 'City',
                        name: 'studio_location_city',
                        css: 'invalid',
                        width: 460,
                        labelWidth: 150,
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('newstudio_location_city').getInputNode().onkeyup = () => verifyField(this, 1, 'saveStudioLocationButton')
                            },
                        }
                    },
                    {
                        view: 'select',
                        options: STATES,
                        id: 'newstudio_location_state',
                        label: 'State',
                        name: 'studio_location_state',
                        width: 460,
                        labelWidth: 150,
                    },
                    {
                        view: 'text',
                        id: 'newstudio_location_zip',
                        label: 'Zipcode',
                        name: 'studio_location_zip',
                        css: 'invalid',
                        width: 460,
                        labelWidth: 150,
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('newstudio_location_zip').getInputNode().onkeyup = () => verifyField(this, 1, 'saveStudioLocationButton')
                            },
                        }
                    },
                    {
                        view: 'text',
                        id: 'newstudio_location_phone_number',
                        name: 'studio_location_phone_number',
                        label: 'Phone Number',
                        placeholder: 'Optional',
                        width: 460,
                        labelWidth: 150,
                        pattern: { mask: "(###) ###-####", allow: /[0-9]/g },
                    },
                    {
                        id: 'saveStudioLocationButton',
                        view: 'button',
                        value: 'Save Studio Location',
                        disabled: true,
                        width: 200,
                        click: function () {
                            let args = window.webix.$$('newStudioLocationForm').getValues()
                            args.studio_location_id = studio_location_id
                            args.studio_id = studio_id
                            sendCommand('saveStudioLocation', args).then(resp => {
                                console.log(resp)
                                if (resp.success) {
                                    let locations = resp.result
                                    window.webix.message({ type: 'success', text: 'Studio Location created successfully' })
                                    window.webix.$$('studiolocationstable').clearAll()
                                    window.webix.$$('studiolocationstable').parse(locations)
                                    window.webix.$$('newstudiolocationwindow').destructor()
                                    if (cb) cb()
                                }
                                else {
                                    window.webix.message({ type: 'error', text: resp.err_msg })
                                }
                            })
                        }
                    },
                ],
            }
        }
    }).show()
    if (studio_location_id) {
        sendCommand('getStudioLocations', {studio_id, studio_location_id}).then(resp => {
            console.log(resp)
            window.webix.$$('newStudioLocationForm').parse(resp.result[0])
            verifyForm('newStudioLocationForm')
        })
    }
}

export function manageLocations(studioinfo, cb) {
    if (window.webix.$$('locations_window')) window.webix.$$('locations_window').destructor()
    sendCommand('getStudioLocations', studioinfo).then(resp => {
        let locations = resp.result
        window.webix.ui({
            view: 'window',
            id: 'locations_window',
            head: 'Studio Locations',
            close: true,
            escHide: true,
            position: 'center',
            width: window.innerWidth * .9,
            height: 400,
            body: {
                rows: [
                    {
                        view: 'toolbar',
                        height: 30,
                        cols: [
                            {
                                view: 'button',
                                value: 'Create Location',
                                click: function () {
                                    addStudio(studioinfo.studio_id)
                                }
                            },
                            {
                                view: 'button',
                                width: 190,
                                value: 'Copy From Studio Info',
                                click: function () {
                                    let args = {}
                                    let mapping = {
                                        studio_id: 'studio_id',
                                        phone_number: 'studio_location_phone_number',
                                    }
                                    Object.keys(studioinfo).forEach(key => {
                                        args[key in mapping ? mapping[key] : key.replace('_', '_location_')] = studioinfo[key]
                                    })
                                    sendCommand('saveStudioLocation', args).then(resp => {
                                        console.log(resp)
                                        if (resp.success) {
                                            let locations = resp.result

                                            window.webix.message({ type: 'success', text: 'Studio Location created successfully' })
                                            window.webix.$$('studiolocationstable').clearAll()
                                            window.webix.$$('studiolocationstable').parse(locations)
                                            if (cb) cb()
                                        }
                                        else {
                                            window.webix.message({ type: 'error', text: resp.err_msg })
                                        }
                                    })
                                }
                            },
                            {
                                view: 'button',
                                value: 'Edit Location',
                                click: function () {
                                    let rec = undefined
                                    window.webix.$$('studiolocationstable').data.each(async row => {
                                        if (row.selected) rec = row
                                    })
                                    if (rec) addStudio(rec.studio_id, rec.studio_location_id, cb)
                                }
                            },
                            {
                                view: 'button',
                                value: 'Manage Rooms',
                                click: function () {
                                    let rec = undefined
                                    window.webix.$$('studiolocationstable').data.each(async row => {
                                        if (row.selected) rec = row
                                    })
                                    if (rec) manageRooms(rec, cb)
                                }
                            },
                            {
                                view: 'button',
                                value: 'Delete Location',
                                click: function () {
                                    let deleted = false
                                    window.webix.$$('studiolocationstable').data.each(async row => {
                                        if (row.selected) {
                                            sendCommand('deleteStudioLocation', { ...row, studio_id: studioinfo.studio_id }).then(() => {
                                                window.webix.message({type: 'success', text: 'Successfully deleted studio locations'})
                                                sendCommand('getStudioLocations', studioinfo).then(resp => {
                                                    console.log('getting locations back', resp)
                                                    window.webix.$$('studiolocationstable').clearAll()
                                                    window.webix.$$('studiolocationstable').parse(resp.result)
                                                    if (cb) cb()
                                                })
                                            })
                                        }
                                    })
                                }
                            },
                        ]
                    },
                    {
                        view: 'scrollview',
                        width: window.innerWidth * .9,
                        height: 400 - 75,
                        scroll: 'auto',
                        body: {
                            view: 'datatable',
                            data: locations,
                            id: 'studiolocationstable',
                            sort: true,
                            resizeColumn: true,
                            columns: [
                                {
                                    id: 'selected',
                                    width: 30,
                                    header: '',
                                    template: '{common.checkbox()}'
                                },
                                {
                                    id: 'studio_id',
                                    hidden: true,
                                },
                                {
                                    id: 'studio_location_id',
                                    hidden: true,
                                },
                                {
                                    id: 'studio_location_name',
                                    header: 'Location Name',
                                    sort: 'string',
                                    width: 200,
                                },
                                {
                                    id: 'room_count',
                                    header: 'Location Rooms',
                                    width: 150,
                                    template: (rec) => {
                                        return rec.room_count + ' Room' + (rec.room_count == 1 ? '' : 's')
                                    }
                                },
                                {
                                    id: 'studio_location_address1',
                                    header: 'Address 1',
                                    sort: 'string',
                                    width: 200,
                                },
                                {
                                    id: 'studio_location_address2',
                                    header: 'Address 2',
                                    sort: 'string',
                                    width: 200,
                                },
                                {
                                    id: 'studio_location_city',
                                    header: 'City',
                                    sort: 'string',
                                    width: 150,
                                },
                                {
                                    id: 'studio_location_state',
                                    header: 'State',
                                    sort: 'string',
                                    width: 70,
                                },
                                {
                                    id: 'studio_location_zip',
                                    header: 'Zipcode',
                                    sort: 'string',
                                    width: 100,
                                },
                                {
                                    id: 'studio_location_phone_number',
                                    header: 'Phone Number',
                                    sort: 'string',
                                    width: 170,
                                    template: (rec) => { console.log(rec); return formatPhone(rec.studio_location_phone_number) } 
                                },
                                //{
                                    //id: 'manage_rooms',
                                    //width: 130,
                                    //header: '',
                                    //template: function (obj) {
                                        //return "<div class='webix_el_button'><button class='webixtype_base'>Manage Rooms</button></div>";
                                    //}
                                //},
                            ],
                            on: {
                                onItemClick: function (info) {
                                    console.log(info)
                                    if (info.column == 'manage_rooms') {
                                        let row = window.webix.$$('studiolocationstable').getItem(info.row)
                                        manageRooms(row)
                                    }
                                }
                            }
                        }
                    }
                ]
            }
        }).show()
    })
}