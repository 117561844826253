import { verifyField, sendCommand, STATES, verifyForm } from '../apis'

const PHONELEN = 10
const EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const newUser = async (account_id, cb, studio_id) => {
    let details = {}
    if (account_id) {
        await sendCommand('searchPeople', {account_id, account_or_student: 'account'}).then(result => {
            console.log('searchPeople returns', result)
            details = result.result[0]
        })
    }
    ;['account_id', 'cell_phone', 'first_name', 'last_name', 'newUserWind', 'newUserForm', 'address1', 'address2', 'city', 'state', 'zip', 'email', 'newUserCreate', 'newUserWin'].forEach(el => {
        if (window.webix.$$(el)) window.webix.$$(el).destructor()
    })
    window.webix.ui({
        view: 'window',
        position: 'center',
        head: account_id ? 'Edit Account' : 'Create Account',
        id: 'newUserWin',
        height: window.innerHeight - 30,
        width: 300,
        close: true,
        escHide: true,
        body: {
            view: 'scrollview',
            scroll: 'y',
            height: window.innerHeight - 30,
            width: 300,
            body: {
                view: 'form',
                id: 'newUserForm',
                data: details,
                elements: [
                    {
                        view: 'text',
                        hidden: true,
                        id: 'account_id',
                        name: 'account_id',
                    },
                    studio_id ? {
                        view: 'text',
                        hidden: true,
                        id: 'studio_id',
                        name: 'studio_id',
                        value: studio_id,
                    } : {hidden: true},
                    {
                        view: "text",
                        id: 'cell_phone',
                        name: 'cell_phone',
                        placeholder: "Cell Number",
                        css: 'invalid',
                        invalid: true,
                        pattern: { mask: "(###) ###-####", allow: /[0-9]/g },
                        on: {
                            onAfterRender: function () {
                                this.focus()
                                window.webix.$$('cell_phone').getInputNode().onkeyup = () => verifyField(this, PHONELEN, 'newUserCreate')
                            },
                        }
                    },
                    {
                        view: "text",
                        id: 'first_name',
                        name: 'first_name',
                        placeholder: "First Name",
                        invalid: true,
                        css: 'invalid',
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('first_name').getInputNode().onkeyup = () => verifyField(this, 1, 'newUserCreate')
                            },
                        },
                    },
                    {
                        view: "text",
                        id: 'last_name',
                        name: 'last_name',
                        placeholder: "Last Name",
                        css: 'invalid',
                        invalid: true,
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('last_name').getInputNode().onkeyup = () => verifyField(this, 1, 'newUserCreate')
                            },
                        },
                    },
                    {
                        view: "text",
                        id: 'address1',
                        name: 'address1',
                        placeholder: "Address Line 1",
                        invalid: true,
                        css: 'invalid',
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('address1').getInputNode().onkeyup = () => verifyField(this, 1, 'newUserCreate')
                            },
                        },
                    },
                    {
                        view: "text",
                        id: 'address2',
                        name: 'address2',
                        placeholder: "Address Line 2",
                    },
                    {
                        view: "text",
                        id: 'city',
                        name: 'city',
                        placeholder: "City",
                        invalid: true,
                        css: 'invalid',
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('city').getInputNode().onkeyup = () => verifyField(this, 1, 'newUserCreate')
                            },
                        },
                    },
                    {
                        view: 'combo',
                        id: 'state',
                        name: 'state',
                        placeholder: "State",
                        options: STATES,
                    },
                    {
                        view: "text",
                        id: 'zip',
                        name: 'zip',
                        placeholder: "Zip Code",
                        invalid: true,
                        css: 'invalid',
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('zip').getInputNode().onkeyup = () => verifyField(this, /\d{5}/, 'newUserCreate')
                            },
                        },
                    },
                    {
                        view: "text",
                        id: 'email',
                        name: 'email',
                        placeholder: "Email Address",
                        invalid: true,
                        css: 'invalid',
                        on: {
                            onAfterRender: function () {
                                window.webix.$$('email').getInputNode().onkeyup = () => verifyField(this, EMAIL, 'newUserCreate')
                            },
                        },
                    },
                    {
                        cols: [
                            {
                                view: "align", align: "center",
                                body: {
                                    view: 'button',
                                    width: 150,
                                    id: 'newUserCreate',
                                    value: account_id ? 'Save Account' : 'Create Account',
                                    css: "webix_primary",
                                    click: () => {
                                        sendCommand('saveAccount', {...window.webix.$$('newUserForm').getValues(), studio_id}).then(resp => {
                                            console.log('post-save', resp)
                                            if (resp.success) {
                                                if ( cb ) cb()
                                                window.webix.message({ type: 'success', text: 'Successfully Saved User' })
                                                window.webix.$$('newUserWin').destructor()
                                            }
                                            else {
                                                window.webix.message({ type: 'error', text: resp.err_msg })

                                            }
                                        })
                                    }
                                },
                            }
                        ]
                    }
                ],
                on: {
                    onAfterLoad: () => {
                        verifyForm('newUserForm')
                    }
                }
            }
        }
    }).show()
}