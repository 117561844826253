import {calculate_rrule, rruleToText} from './calculate_rrule'
import { RRule } from 'rrule'

export const wording = {
    'whatsdueatregistration_registration_fee': 'Registration Fee',
    'whatsdueatregistration_first_month': 'First Month Tuition (Of Session - applies to Monthly payments only)',
    'whatsdueatregistration_last_month': 'Last Month Tuition (Of Session - applies to Monthly payments only)',
    'whatsdueatregistration_all_tuition': 'All Tuition',
}

/*
function isUndefined(a) {
    return typeof a == "undefined";
}

function isArray(obj) {
    return Array.isArray(obj);
}

//window.webix.editors.myeditor = webix.extend({
//render:function(){
//return webix.html.create("div", {
//"class":"webix_dt_editor"
//}, "<input type='email'>");
//},
//
//}, webix.editors.text);

export const dateoptions = {
    view: "checksuggest",
    minWidth: 100,
    yCount: 10,
    data: [
        { id: '1', value: '1st' },
        { id: '2', value: '2nd' },
        { id: '3', value: '3rd' },
        { id: '4', value: '4th' },
        { id: '5', value: '5th' },
        { id: '6', value: '6th' },
        { id: '7', value: '7th' },
        { id: '8', value: '8th' },
        { id: '9', value: '9th' },
        { id: '10', value: '10th' },
    ]
}

const evs = ["scroll", "focus", "blur", "paste", "cut", "keyup", "input"];
const _events = []
const env = {}

function getLabel(config) {
    var text = config.header && config.header[0] ? config.header[0].text : config.editValue || config.label;
    return (text || "").toString().replace(/<[^>]*>/g, "");
}

function create_suggest(config) {
    if (typeof config == "string") return config;
    if (config.linkInput) return config._settings.id;

    if (typeof (config) == "object") {
        if (isArray(config)) config = {
            data: config
        };
        config.view = config.view || "list";
    } else if (config === true) config = {
        view: "list"
    };
    console.log('config for suggest', config)
    var obj = window.webix.ui(config);
    return obj.config.id;
}

function create(name, attrs, html) {
    attrs = attrs || {};
    var node = document.createElement(name);

    for (var attr_name in attrs) {
        node.setAttribute(attr_name, attrs[attr_name]);
    }

    if (attrs.style) node.style.cssText = attrs.style;
    if (attrs["class"]) node.className = attrs["class"];
    if (html) node.innerHTML = html;
    return node;
}


const callEvent = function (a, b) {
    return window.webix.EventSystem.callEvent(a, b);
};

function attach_editend(suggest) {
    if (suggest && suggest.setMasterValue && !suggest._editor_initialized) {
        suggest._editor_initialized = true;
        suggest.attachEvent("onValueSuggest", function () {
            delay(function () {
                //return callEvent("onEditEnd", []);
            });
        });
    }
}

function delay(method, obj, params, delay) {
    return window.setTimeout(function () {
        if (!(obj && obj.$destructed)) {
            let ret = method.apply(obj, params || []);
            method = obj = params = null;
            return ret;
        }
    }, delay || 1);
}

function bind(functor, object) {
    return function () {
        return functor.apply(object, arguments);
    };
} //evaluate javascript code in the global scope

let _seed = new Date().valueOf();

function uid() {
    _seed++;
    return _seed;
} //resolve ID as html object


function toNode(node) {
    if (typeof node == "string") return document.getElementById(node);
    return node;
} //resolve function name

function event$1(node, event, handler, context) {
    context = context || {};
    node = toNode(node);
    let id = context.id || uid();
    if (context.bind) handler = bind(handler, context.bind);
    let info = [node, event, handler, context.capture];
    if (!context.inner) _events[id] = info; //store event info, for detaching

    let capture = !!context.capture;
    if (!isUndefined(context.passive) && env.passiveEventListeners) //blockable touch events
        capture = {
            passive: context.passive,
            capture: capture
        };
    node.addEventListener(event, handler, capture);
    return id; //return id of newly created event, can be used in eventRemove
} //remove previously attached event


function _event(a, b, c, d) {
    d = d || {};
    d.inner = true;
    event$1(a, b, c, d);
} //attach event to the DOM element

*/

let daypickerField

window.adjustchecks = (that) => {
    console.log('that')
    if (that.id == 'whatsdueatregistration_first_month' || that.id == 'whatsdueatregistration_last_month') {
        document.getElementById('whatsdueatregistration_all_tuition').checked = false
    }
    else {
        document.getElementById('whatsdueatregistration_first_month').checked = false
        document.getElementById('whatsdueatregistration_last_month').checked = false
    }
}

export const buildEditors = () => {
    window.webix.editors.whatsdueatregistration = window.webix.extend({
        focus: function() {
            console.log('focus')
            if (! this.config.mappings) {
                this.getMappings()
            }
        },
        render:function(a){
            console.log('render')
            if (! this.config.mappings) {
                this.getMappings()
            }
            console.log('got here')
            let values = this.parseValues()
            console.log('values are', values)
            return window.webix.html.create("div", {
                "class":"webix_dt_editor"
              }, `
                <div style='background-color: #f7f8fb; border: 1px solid #EDEFF0; padding: 3px !important; height: 90px;'>
                <input style='width: 20px; height: 12px; margin-top: 5px; float: left; clear: both' type='checkbox' id='whatsdueatregistration_registration_fee' ${values.indexOf('whatsdueatregistration_registration_fee') >= 0 ? 'checked' : ''} />
                    <label for='whatsdueatregistration_registration_fee' style='float: left; user-select: none;'> ${wording.whatsdueatregistration_registration_fee}</label>
                <input style='width: 20px; height: 12px; margin-top: 5px; float: left; clear: both' type='checkbox' onchange='window.adjustchecks(this)' id='whatsdueatregistration_first_month' ${values.indexOf('whatsdueatregistration_first_month') >= 0 ? 'checked' : ''} />
                    <label for='whatsdueatregistration_first_month' style='float: left; user-select: none;'> ${wording.whatsdueatregistration_first_month}</label>
                <input style='width: 20px; height: 12px; margin-top: 5px; float: left; clear: both' type='checkbox' onchange='window.adjustchecks(this)' id='whatsdueatregistration_last_month' ${values.indexOf('whatsdueatregistration_last_month') >= 0 ? 'checked' : ''} />
                    <label for='whatsdueatregistration_last_month' style='float: left; user-select: none;'> ${wording.whatsdueatregistration_last_month}</label>
                <input style='width: 20px; height: 12px; margin-top: 5px; float: left; clear: both' type='checkbox' onchange='window.adjustchecks(this)' id='whatsdueatregistration_all_tuition' ${values.indexOf('whatsdueatregistration_all_tuition') >= 0 ? 'checked' : ''} />
                    <label for='whatsdueatregistration_all_tuition' style='float: left; user-select: none;'> ${wording.whatsdueatregistration_all_tuition}</label>
                </div>
                `
            )
        },
        getMappings: function() {
            this.config.mappings = Object.fromEntries(Object.entries(wording).map(a => a.reverse()))  // switch wording key/value to value/key 
        },
        parseValues: function() {
            console.log('parseValues')
            if (! this.config.mappings) {
                this.getMappings()
            }
            let val = this.config._value
            if ( typeof(val) == 'object' ) return val
            let parts = val.split(' + ')
            let vals = []
            parts.forEach(x => {
                if ( x ) {
                    vals.push(this.config.mappings[x.split('>')[1].split('<')[0]])
                }
            })
            return vals
        },
        setValue: function(ignoreValue) {
            console.log('setvalue')
            if (! this.config.mappings) {
                this.getMappings()
            }
            console.log('setvalue')
            console.log(this.config._value)
            let values = this.parseValues()
            Object.values(this.config.mappings).forEach(x => {
                if ( document.getElementById(x) ) document.getElementById(x).checked = false
            })
            values.forEach(x => {
                if ( x ) {
                    console.log(x)
                    console.log(document.getElementById(x))
                    if ( document.getElementById(x) ) document.getElementById(x).checked = true
                }
            })
        },
        getValue: function(val) {
            console.log('getvalue', val)
            let values = []
            if ( document.getElementById('whatsdueatregistration_registration_fee').checked ) values.push(wording.whatsdueatregistration_registration_fee)
            if ( document.getElementById('whatsdueatregistration_first_month').checked ) values.push(wording.whatsdueatregistration_first_month)
            if ( document.getElementById('whatsdueatregistration_last_month').checked ) values.push(wording.whatsdueatregistration_last_month)
            if ( document.getElementById('whatsdueatregistration_all_tuition').checked ) values.push(wording.whatsdueatregistration_all_tuition)
            this.config._value = values.map(x => this.config.mappings[x])
            return values.map(x => `<span style='background-color: #fff;'>` + x + `</span>`).join(' + ')
        }
    }, window.webix.editors.text);

    window.selectradio = (which) => {
        document.getElementById(which).checked = true
    }

    window.getSelectedValue = (id) => {
        let f = document.getElementById(id)
        if ( ! f ) return
        console.log(id + ' -> ' + f.selectedIndex)
        if ( ! f.selectedIndex && f.selectedIndex != 0 ) return
        if ( ! f.options[f.selectedIndex] ) return
        return f.options[f.selectedIndex].value
    }

    window.enhancedRRuleText = rruleToText

    window.webix.editors.rrule_tuition = window.webix.extend({
        focus: function() {},
        getValue: function() {
            let weekdays = []
            let relativeDay = []
            let bymonthday = []
            if ( document.getElementById('whichmonthlyrelative').checked ) {
                relativeDay = [window.getSelectedValue('relativeday')]
                weekdays = [window.getSelectedValue('weekdays')]
            }
            if ( document.getElementById('whichmonthlyday').checked ) {
                bymonthday = [window.getSelectedValue('bymonthday')]
            }
            let rrule = calculate_rrule(undefined, undefined, 'monthly', undefined, weekdays, relativeDay, bymonthday, true)
            this.config.rrule = rrule.toString()
            return window.enhancedRRuleText(rrule)
        },
        render:function(){
            console.log('value for rrule', this.config.rrule)
            let rrule = RRule.fromString(this.config.rrule)
            console.log(rrule)
            let bymonthday = rrule.origOptions.bymonthday && rrule.origOptions.bymonthday.length ? rrule.origOptions.bymonthday[0] : undefined
            if (! bymonthday ) {
                bymonthday = ('bymonthday' in rrule.origOptions) ? rrule.origOptions.bymonthday : undefined
            }
            let bysetpos = typeof(rrule.origOptions.bysetpos) == 'string' || typeof(rrule.origOptions.bysetpos) == 'number' ? rrule.origOptions.bysetpos : rrule.origOptions.bysetpos && rrule.origOptions.bysetpos.length ? rrule.origOptions.bysetpos[0] : undefined
            let word = {
                '-1': 'last',
                '1': 'first',
                '2': 'second',
                '3': 'third',
                '4': 'fourth',
            }[String(bysetpos)]
            let byweekday = rrule.options.byweekday && rrule.options.byweekday.length ? rrule.options.byweekday[0] : undefined
            let dow = {
                '0': 'mo',
                '1': 'tu',
                '2': 'we',
                '3': 'th',
                '4': 'fr',
                '5': 'sa',
                '6': 'su',
            }[String(byweekday)]
            dow = dow ? dow : 'day'
    
            console.log('rrule', rrule)
            console.log('bymonthday', bymonthday)
            console.log('bysetpos', bysetpos)
            console.log('byweekday', byweekday)
            console.log('word', word)
            console.log('dow', dow)
            let relative = ! bymonthday || bymonthday == -1 ? 'checked' : ''
            let day = bymonthday && bymonthday != -1 ? 'checked' : ''
            if (bymonthday == -1) {
                bysetpos = -1
                dow = 'day'
            }
            return window.webix.html.create("div", {
                "class":"webix_dt_editor"
              }, `
                <div style='background-color: #f7f8fb; border: 1px solid #EDEFF0; padding: 3px !important;'>
                <div style="clear: both; background-color: #f7f8fb; height: 25px">
                <input style="float: left; width: 20px; height: 12px; margin-top: 5px !important" ${relative} type='radio' id='whichmonthlyrelative' name='whichmonthly' value='relative' />
                <label for='whichmonthlyrelative' style="float: left; width: 55px">On the</label>
                <select id='relativeday' style="float: left; width: 120px" onchange="window.selectradio('whichmonthlyrelative')">
                    <option value='First' ${! bysetpos || bysetpos == 1 ? 'selected' : ''}>First</option>
                    <option value='Second' ${bysetpos == 2 ? 'selected' : ''}>Second</option>
                    <option value='Third' ${bysetpos == 3 ? 'selected' : ''}>Third</option>
                    <option value='Fourth' ${bysetpos == 4 ? 'selected' : ''}>Fourth</option>
                    <option value='Last' ${bysetpos == -1 ? 'selected' : ''}>Last</option>
                </select>
                <select id='weekdays' style="float: left; width: 120px; margin-left: 5px;" onchange="window.selectradio('whichmonthlyrelative')">
                    <option value='Day' ${!dow || dow == 'day' ? 'selected' : ''}>Day</option>
                    <option value='su' ${dow == 'su' ? 'selected' : ''}>Sunday</option>
                    <option value='mo' ${dow == 'mo' ? 'selected' : ''}>Monday</option>
                    <option value='tu' ${dow == 'tu' ? 'selected' : ''}>Tuesday</option>
                    <option value='we' ${dow == 'we' ? 'selected' : ''}>Wednesday</option>
                    <option value='th' ${dow == 'th' ? 'selected' : ''}>Thursday</option>
                    <option value='fr' ${dow == 'fr' ? 'selected' : ''}>Friday</option>
                    <option value='sa' ${dow == 'sa' ? 'selected' : ''}>Saturday</option>
                </select>
                <div style="float: left; width: 120px; margin-left: 5px;">of the month</div>
                </div>
                <div style='clear: both; background-color: #f7f8fb; margin-top: 5px; height: 25px'>
                <input style="float: left; width: 20px; height: 12px; margin-top: 5px !important" ${day} type='radio' id='whichmonthlyday' name='whichmonthly' value='day' />
                <label for='whichmonthlyday' style="float: left; width: 55px">On the</label>
                <select id='bymonthday' style="float: left; width: 70px" onchange="window.selectradio('whichmonthlyday')">
                    <option value='1' ${bymonthday == 1 || ! bymonthday ? 'selected' : ''}>1st</option>
                    <option value='2' ${bymonthday == 2 ? 'selected' : ''}>2nd</option>
                    <option value='3' ${bymonthday == 3 ? 'selected' : ''}>3rd</option>
                    <option value='4' ${bymonthday == 4 ? 'selected' : ''}>4th</option>
                    <option value='5' ${bymonthday == 5 ? 'selected' : ''}>5th</option>
                    <option value='6' ${bymonthday == 6 ? 'selected' : ''}>6th</option>
                    <option value='7' ${bymonthday == 7 ? 'selected' : ''}>7th</option>
                    <option value='8' ${bymonthday == 8 ? 'selected' : ''}>8th</option>
                    <option value='9' ${bymonthday == 9 ? 'selected' : ''}>9th</option>
                    <option value='10' ${bymonthday == 10 ? 'selected' : ''}>10th</option>
                    <option value='11' ${bymonthday == 11 ? 'selected' : ''}>11th</option>
                    <option value='12' ${bymonthday == 12 ? 'selected' : ''}>12th</option>
                    <option value='13' ${bymonthday == 13 ? 'selected' : ''}>13th</option>
                    <option value='14' ${bymonthday == 14 ? 'selected' : ''}>14th</option>
                    <option value='15' ${bymonthday == 15 ? 'selected' : ''}>15th</option>
                    <option value='16' ${bymonthday == 16 ? 'selected' : ''}>16th</option>
                    <option value='17' ${bymonthday == 17 ? 'selected' : ''}>17th</option>
                    <option value='18' ${bymonthday == 18 ? 'selected' : ''}>18th</option>
                    <option value='19' ${bymonthday == 19 ? 'selected' : ''}>19th</option>
                    <option value='20' ${bymonthday == 20 ? 'selected' : ''}>20th</option>
                    <option value='21' ${bymonthday == 21 ? 'selected' : ''}>21st</option>
                    <option value='22' ${bymonthday == 22 ? 'selected' : ''}>22nd</option>
                    <option value='23' ${bymonthday == 23 ? 'selected' : ''}>23rd</option>
                    <option value='24' ${bymonthday == 24 ? 'selected' : ''}>24th</option>
                    <option value='25' ${bymonthday == 25 ? 'selected' : ''}>25th</option>
                    <option value='26' ${bymonthday == 26 ? 'selected' : ''}>26th</option>
                    <option value='27' ${bymonthday == 27 ? 'selected' : ''}>27th</option>
                    <option value='28' ${bymonthday == 28 ? 'selected' : ''}>28th</option>
                    <option value='29' ${bymonthday == 29 ? 'selected' : ''}>29th</option>
                    <option value='30' ${bymonthday == 30 ? 'selected' : ''}>30th</option>
                    <option value='31' ${bymonthday == 31 ? 'selected' : ''}>31st</option>
                </select>
                <div style="float: left; width: 140px; margin-left: 10px;">day of the month</div>
                </div>
                </div>
            `)
        }
    }, window.webix.editors.text);

    window.webix.editors.rrule2 = window.webix.extend({
        focus: function () { },
        getValue: function (node) {
            //return this.getInputNode().data.edit;
        },
        setValue: function (value) {
            //this.getPopup().show(this.node);
            //this.getInputNode().data.edit = value;
            //this.getInputNode().refresh();
        },
        //getPopup: function () {
            //var id = this.config.$popup;
            //if (!(id && window.webix.$$(id))) id = this.config.$popup = this.createPopup();
            //return window.webix.$$(id);
        //},
        render: function() {
            return window.webix.ui({
                rows: [
                    {
                        view: 'template',
                        template: 'test1',
                    },
                    {
                        view: 'template',
                        template: 'test2',
                    }
                ]
            })
        }
    }, window.webix.editors.text);

    window.webix.editors.$popup.daypicker = {
        view: "popup",
        width: 250,
        height: 250,
        padding: 0,
        body: {
            view: "datatable",
            borderless: true,
            id: '_datepicker',
            select: true,
            headerRowHeight: 0,
            height: 150,
            scroll: 'y',
            select: true,
            multiselect: true,
            columns: [
                {
                    id: 'selected',
                    template: row => {
                        return "<input type='checkbox' " + (row.checked ? 'checked' : '') + ' />'
                    }
                },
                {
                    id: 'value',
                    header: '',
                }
            ],
            data: [
                { id: '1', value: '1st' },
                { id: '2', value: '2nd' },
                { id: '3', value: '3rd' },
                { id: '4', value: '4th' },
                { id: '5', value: '5th' },
                { id: '6', value: '6th' },
                { id: '7', value: '7th' },
                { id: '8', value: '8th' },
                { id: '9', value: '9th' },
                { id: '10', value: '10th' },
                { id: '11', value: '11th' },
                { id: '12', value: '12th' },
                { id: '13', value: '13th' },
                { id: '14', value: '14th' },
                { id: '15', value: '15th' },
                { id: '16', value: '16th' },
                { id: '17', value: '17th' },
                { id: '18', value: '18th' },
                { id: '19', value: '19th' },
                { id: '20', value: '20th' },
                { id: '21', value: '21st' },
                { id: '22', value: '22nd' },
                { id: '23', value: '23rd' },
                { id: '24', value: '24th' },
                { id: '25', value: '25th' },
                { id: '26', value: '26th' },
                { id: '27', value: '27th' },
                { id: '28', value: '28th' },
                { id: '29', value: '29th' },
                { id: '30', value: '30th' },
                { id: '31', value: '31st' },

            ],
            on: {
                onItemClick: a => {
                    let dt = window.webix.$$('_datepicker')
                    let rec = dt.getItem(a.row)
                    rec.checked = !rec.checked
                    dt.updateItem(a.row, rec)
                    let days = dt.serialize().filter(x => x.checked).map(x => x.id).join(',')
                    window.webix.$$(daypickerField.config.tableid).updateItem(window.webix.$$(daypickerField.config.tableid).getIdByIndex(0), { days })
                }
            }
        }
    }

    window.webix.editors.daypicker = window.webix.extend({
        focus: function () { },
        popupType: "daypicker",
        setValue: function (value) {
            if (!value) return
            let table = this.getPopup().getChildViews()[0]
            value.split(',').forEach(val => {
                let rec = table.getItem(val)
                rec.checked = true
                table.updateItem(val, rec)
            })
        },
        getValue: function () {
            console.log('getValue')
            let table = this.getPopup().getChildViews()[0]
            return table.serialize().filter(x => x.checked).map(x => x.id).join(',')
        },
        popup_setter: function (popup) {
            console.log('setter')
        },
        popupInit: function (popup) {
            daypickerField = this
            console.log('in popup init')
            //popup.getChildViews()[0].attachEvent("onAfterDateSelect", function (value) {
            //callEvent("onEditEnd", [value]);
            //});
        }
    }, window.webix.editors.popup)

}