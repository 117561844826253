import {sendCommand} from '../apis'

export function manageSessions(studioinfo, cb) {
    if (window.webix.$$('sessions_window')) window.webix.$$('sessions_window').destructor()
    sendCommand('getStudioSessions', studioinfo).then(resp => {
        let sessions = resp.result
        console.log(resp)
        window.webix.ui({
            view: 'window',
            id: 'sessions_window',
            head: 'Studio Sessions',
            close: true,
            escHide: true,
            position: 'center',
            scroll: 'auto',
            width: 795,  //TODO: make this 300 again
            height: 400,
            body: {
                rows: [
                    {
                        view: 'toolbar',
                        height: 30,
                        cols: [
                            {
                                view: 'button',
                                value: 'Create Session',
                                click: function () {
                                    window.webix.$$('studionsessiontable').parse({ studio_id: studioinfo.studio_id })
                                    let id = window.webix.$$('studionsessiontable').getLastId()
                                    window.webix.$$('studionsessiontable').editCell(id, 'studio_session_name', false, true)
                                }
                            },
                            {
                                view: 'button',
                                value: 'Delete Session',
                                click: function () {
                                    window.webix.$$('studionsessiontable').data.each(row => {
                                        if ( row.selected ) {
                                            sendCommand('deleteStudioSession', {studio_id: studioinfo.studio_id, studio_session_id: row.studio_session_id}).then(resp => {
                                                if (resp.success) {
                                                    window.webix.message({ type: 'success', text: 'Successfully Deleted' })
                                                    sendCommand('getStudioSessions', studioinfo).then(resp => {
                                                        console.log('getting sessions back', resp)
                                                        window.webix.$$('studionsessiontable').clearAll()
                                                        window.webix.$$('studionsessiontable').parse(resp.result)
                                                    })
                                                    if (cb) cb()
                                                }
                                                else {
                                                    window.webix.message({ type: 'error', text: resp.err_msg })
                                                }
                                            })
                                        }
                                    })
                                }
                            },
                        ]
                    },
                    {
                        view: 'datatable',
                        editable: true,
                        scroll: 'auto',
                        data: sessions,
                        resizeColumn: true,
                        sort: true,
                        id: 'studionsessiontable',
                        columns: [
                            {
                                id: 'selected',
                                width: 30,
                                header: '',
                                template: '{common.checkbox()}'
                            },
                            {
                                id: 'studio_id',
                                hidden: true,
                            },
                            {
                                id: 'studio_session_id',
                                hidden: true,
                            },
                            {
                                id: 'studio_session_name',
                                header: 'Session Name',
                                width: 270,
                                editor: 'text',
                                sort: 'string',
                            },
                            {
                                id: 'studio_session_start_date',
                                header: 'Session Start Date',
                                width: 160,
                                editor: 'date',
                                sort: 'date',
                            },
                            {
                                id: 'studio_session_end_date',
                                header: 'Session End Date',
                                width: 160,
                                editor: 'date',
                                sort: 'date',
                            },
                            {
                                id: 'studio_session_deadline_date',
                                header: 'Signup Deadline Date',
                                width: 160,
                                editor: 'date',
                                sort: 'date',
                            },
                        ],
                        on: {
                            onAfterEditStop: function (value, e, ignore) {
                                if (ignore || ! window.webix.$$('studionsessiontable'))  return
                                if (value.value != value.old) {
                                    let rec = window.webix.$$('studionsessiontable').getItem(e.row)
                                    console.log('update', rec)
                                    if ( rec.studio_session_deadline_date && rec.studio_session_deadline_date.toISOString ) rec.studio_session_deadline_date = rec.studio_session_deadline_date.toISOString().split('T')[0]
                                    if ( rec.studio_session_start_date && rec.studio_session_start_date.toISOString ) rec.studio_session_start_date = rec.studio_session_start_date.toISOString().split('T')[0]
                                    if ( rec.studio_session_end_date && rec.studio_session_end_date.toISOString ) rec.studio_session_end_date = rec.studio_session_end_date.toISOString().split('T')[0]
                                    sendCommand('saveStudioSession', rec).then(resp => {
                                        console.log('resp', resp)
                                        if (resp.success) {
                                            window.webix.message({ type: 'success', text: 'Successfully Changed' })
                                            window.webix.$$('studionsessiontable').updateItem(rec.id, resp.result[0])
                                            if (cb) cb()
                                            //window.webix.$$('studionsessiontable').parse(resp.result)
                                        }
                                        else {
                                            window.webix.message({ type: 'error', text: resp.err_msg })
                                        }
                                    })
                                }
                            }
                        },
                    }
                ]
            },
        }).show()
    })
}