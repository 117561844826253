import { formatPhone, sendCommand } from '../apis'
import {manageLevels} from '../levels/manageLevels'
import {manageAdmins} from './manageAdmins'
import {createStudio} from './createStudio'
import {manageLocations} from './manageLocations'
import {manageSessions} from './manageSessions'
import {showAccountsForStudio} from '../accounts/manageAccounts'
import {manageClasses} from '../classes/adminClasses'
import {manageStudioPaymentOptions} from './manageStudioPaymentOptions'

let selectedId = undefined

async function updateStudio(rec) {
    let results = await sendCommand('updateStudio', rec)
    return results
}

let loaded = false
export const adminStudios = (props) => {
    //window.webix.protoUI({
//name:"activeTable",
//}, window.webix.ui.datatable, window.webix.ActiveContent);
    return {
        rows: [
            {
                view: 'toolbar',
                cols: [
                    {
                        view: "button",
                        width: 150,
                        id: 'createbutton',
                        value: "Create Studio",
                        click: createStudio,
                        on: {
                            onAfterRender: async function () {
                                let data = await sendCommand('getAllStudios', {})
                                console.log('data is', data)
                                window.webix.$$('admin_studios').clearAll()
                                window.webix.$$('admin_studios').parse(data.result.studios)
                                window.webix.$$('admin_studios').data.attachEvent('onStoreUpdated', async function (id, rec, action) {
                                    if (action == 'save') {  // this is for the checkboxes
                                        await updateStudio(rec)
                                    }
                                })
                            }
                        }
                    },
                ],
            },
            {
                view: 'datatable',
                id: 'admin_studios',
                data: [],
                //scroll: 'auto',
                width: window.innerWidth * .90,
                sort: 'multi',
                resizeColumn: true,
                //select: true,
                tooltip: true,
                leftSplit: 3,
                onClick: {
                    navselect: function(e, id) {
                        console.log('id', id)
                        console.log('e', e)
                        console.log('target', e.target)
                        const menuactions = {
                            'manageAdmins': () => manageAdmins(window.webix.$$('admin_studios').getItem(id.row).studio_id),
                            'manageLocations': () => manageLocations(window.webix.$$('admin_studios').getItem(id.row)),
                            'manageLevels': () => manageLevels(window.webix.$$('admin_studios').getItem(id.row)),
                            'manageSessions': () => manageSessions(window.webix.$$('admin_studios').getItem(id.row)),
                            'manageClasses': () => manageClasses(window.webix.$$('admin_studios').getItem(id.row)),
                            'manageAccounts': () => showAccountsForStudio(window.webix.$$('admin_studios').getItem(id.row)),
                            'settings': () => manageStudioPaymentOptions(window.webix.$$('admin_studios').getItem(id.row)),
                        }
                        let action = e.target.options[e.target.selectedIndex].getAttribute('action')
                        //window.webix.message(action)
                        console.log('action: ', action)
                        if ( action ) {
                            menuactions[action]()
                            e.target.selectedIndex = 0
                        }
                    }
                },
                columns: [
                    { id: 'studio_id', hidden: true },
                    {
                        id: 'action',
                        tooltip: false,
                        //template: `<select class='navselect' onFocus="this.style.width=this.offsetWidth+20; this.size=this.options.length" onBlur="this.size=0">
                        template: `<select class='navselect'>
                                <option>Select an Option</option>
                                <option action="manageAccounts">Manage Accounts</option>
                                <option action="manageAdmins">Manage Admins</option>
                                <option action="manageLocations">Manage Locations</option>
                                <option action="manageLevels">Manage Levels</option>
                                <option action="manageSessions">Manage Sessions</option>
                                <option action="manageClasses">Manage Classes</option>
                                <option action="settings">Settings</option>
                            </select>`,
                        width:155
                    },
                    { id: 'studio_name', header: { text: 'Studio Name', content: 'textFilter' }, width: 150, sort: 'string' },
                    { id: 'studio_address1', header: 'Address', width: 150, sort: 'string' },
                    { id: 'studio_address2', header: 'Address2', width: 150, sort: 'string' },
                    { id: 'studio_city', header: 'City', width: 150, sort: 'string' },
                    { id: 'studio_state', header: 'State', width: 150, sort: 'string' },
                    { id: 'studio_zip', header: 'Zip', width: 150, sort: 'string' },
                    { id: 'studio_timezone', header: 'Timezone', width: 150, sort: 'string' },
                    { id: 'phone_number', header: 'Phone Number', width: 150, sort: 'int', template: (rec) => { return formatPhone(rec.phone_number) } },
                    { id: 'studio_email', header: 'Email', width: 150, sort: 'string' },
                    { id: 'owners', header: 'Owners', sort: 'string' },
                    { id: 'creation_date', header: 'Creation Date', width: 150, sort: 'string' },
                    { id: 'open_enrollment', header: { text: 'Public Enrollment', autoheight: true, css: 'multiline' }, template: "{common.checkbox()}" },
                    { id: 'active', header: 'Active', template: "{common.checkbox()}" },
                    { id: 'admins', hidden: true },
                ],
                //this is loaded on an after event on the button
                on: {
                    onItemClick: function (info, e) {
                        console.log('refs are', e)
                        console.log(info)
                        if (info.column == 'action') {
                            return true
                        }
                        createStudio(info.row)
                        window.webix.$$('admin_new_studio').parse(window.webix.$$('admin_studios').getItem(info.row))
                    }
                }
            },
        ],
    }
}
