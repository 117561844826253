import { login } from './login'

export const logout = (props) => {
    props.setPermission(['Guest'])
    localStorage.removeItem('info')
    return {
        id: 'logout',
        view: 'align',
        width: 300,
        align: 'middle,center',
        body: {
            id: 'logoutbody',
            view: 'template',
            template: 'You have been logged out',
            borderless: true,
            width: 200,
            height: 20,
            on: {
                onAfterRender: function () {
                    window.setTimeout(() => {
                        while (window.webix.$$('pages').getChildViews().length) {
                            window.webix.$$('pages').removeView(window.webix.$$('pages').getChildViews()[0])
                        }
                        window.webix.$$('header').setHTML('Please Log In or Create Account')
                        window.webix.$$('pages').addView(login(props))
                    }, 2000);
                }
            }
        }
    }
}