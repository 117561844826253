import { formatPhone, sendCommand, STATES, verifyForm } from '../apis'
import { newUser } from './newUser'
import { accountTransactions } from '../accounts/accountTransactions'
import { creditCard } from '../square/ccform'
import { findStudio } from '../findStudio'
import { students } from '../students/manageStudents'
import { studentEnrollment } from '../students/manageStudents'
import { newStudent } from '../students/manageStudents'

let mode = 'Accounts'

function doSearch(which) {
    let search = { account_or_student: which || 'account' }
        ;['searchusers_studio_id', 'searchusers_cell_phone', 'searchusers_first_name', 'searchusers_last_name', 'searchusers_city', 'searchusers_state'].forEach(field => {
            field = which == 'account' ? field : field.replace('users_', 'student_')
            console.log(field)
            if (window.webix.$$(field) && window.webix.$$(field).getValue && window.webix.$$(field).getValue()) {
                search[field.replace('searchusers_', '').replace('searchstudent_', '')] = window.webix.$$(field).getValue()
            }
        })
    sendCommand('searchPeople', search).then(resp => {
        console.log('search results', resp)
        window.webix.$$(mode.toLowerCase()).clearAll()
        window.webix.$$(mode.toLowerCase()).parse(resp.result)
        if (!resp.result.length) {
            // remove ! -> window.webix.$$('searchusers_criteria').collapse()
            window.webix.message({ type: 'error', text: 'No results found' })
        }
    })
}

function showUserStudios(row) {
    console.log('showing studios', row)
    sendCommand('getStudiosForAccountID', row).then(registrationresp => {
        console.log('here are the registered studios', registrationresp)
        if (window.webix.$$('userstudios')) window.webix.$$('userstudios').destructor()
        window.webix.ui({
            view: 'window',
            id: 'userstudios',
            head: 'User Studios',
            close: true,
            escHide: true,
            position: 'center',
            width: window.innerWidth * .8,
            body: {
                rows: [
                    {
                        view: 'toolbar',
                        height: 30,
                        elements: [
                            {
                                view: 'button',
                                width: 150,
                                value: 'Register Studio',
                                click: () => {
                                    findStudio(row.account_id, 'Register Studio', (rows) => {
                                        console.log(rows, registrationresp)
                                        window.webix.$$('registered_studios').parse(rows)
                                        rows.forEach(r => {
                                            sendCommand('registerUserToStudio', { studio_id: r.studio_id, account_id: row.account_id }).then(resp => {
                                                if (resp.success) {
                                                    window.webix.message({ type: 'success', text: 'Registered to Studio successfully' })
                                                }
                                                else {
                                                    window.webix.message({ type: 'error', text: resp.err_msg })
                                                }
                                            })
                                        })
                                    })
                                }
                            },
                            {
                                view: 'button',
                                width: 150,
                                value: 'Unregister Studio',
                                click: () => {
                                    //TODO: possibly use an active flag for this?  we don't want to lose the history
                                }
                            }
                        ]
                    },
                    {
                        view: 'datatable',
                        resizeColumn: true,
                        sort: true,
                        select: true,
                        scheme: {
                            $change: function (row) {
                                console.log('setting up style', row)
                                row.$css = row.active ? '' : 'strikethrough'
                            }
                        },
                        id: 'registered_studios',
                        data: registrationresp.result,
                        columns: [
                            {
                                id: 'account_id',
                                hidden: true,
                            },
                            {
                                id: 'selected',
                                width: 30,
                                header: '',
                                template: '{common.checkbox()}'
                            },
                            {
                                id: 'studio_name',
                                header: 'Studio Name',
                                sort: 'string',
                                width: 250,
                            },
                            {
                                id: 'studio_city',
                                header: 'City',
                                sort: 'string',
                                width: 150,
                            },
                            {
                                id: 'studio_state',
                                header: 'State',
                                sort: 'string',
                                width: 80,
                            },
                            {
                                id: 'phone_number',
                                header: 'Phone Number',
                                width: 150,
                                sort: 'string',
                                template: (row => {
                                    return formatPhone(row.phone_number)
                                })
                            },
                            {
                                id: 'active',
                                header: 'Registered',
                                sort: 'string',
                                template: function (row) {
                                    return row.active ? 'Yes' : 'No'
                                }
                            },
                        ],
                        on: {
                            onItemClick: function (cell) {
                                if (cell.column == 'active') {
                                    let rec = this.getItem(cell.row)
                                    this.updateItem(cell.row, { active: !rec.active })
                                    console.log('before saving:', rec)
                                    sendCommand('changeRegistrationUserToStudio', { ...rec, account_id: row.account_id }).then(results => {
                                        if (results.success) {
                                            window.webix.message({ type: 'success', text: 'Successfully changed' })
                                        }
                                        else {
                                            window.webix.message({ type: 'error', text: results.err_msg })
                                        }
                                    })
                                }
                            }
                        },
                    }
                ]
            }
        }).show()
    })
}

export function showAccountsForStudio(row) {
    console.log('showing accounts for studio', row.studio_id)
    sendCommand('getAccountsForStudio', row).then(accountsresp => {
        console.log('here are the accounts for the studio', accountsresp)
        if (window.webix.$$('studioaccounts')) window.webix.$$('studioaccounts').destructor()
        window.webix.ui({
            view: 'window',
            id: 'studioaccounts',
            head: 'Studio Accounts',
            close: true,
            escHide: true,
            position: 'center',
            width: window.innerWidth * .8,
            height: window.innerHeight - 75,
            body: adminAccounts(row.studio_id)
        }).show()
    })
}


export function adminAccounts(studio_id) {
    if (studio_id && typeof (studio_id) === 'object') {
        studio_id = undefined
    }
    console.log('studio_id', studio_id)
    return {
        id: 'userspage',
        multi: true,
        rows: [
            {
                view: 'template',
                height: 25,
                template: '<div style="font-weight: bold;">Search Accounts and Students</div>',
                borderless: true,
            },
            {
                height: 210,
                view: 'tabview',
                tabbar: {
                    optionWidth: 200,
                    on: {
                        onChange: (viewname) => {
                            let label = window.webix.$$(viewname).getChildViews()[window.webix.$$(viewname).getChildViews().length - 1].config.value.split(' ')[1]
                            mode = label
                            label = label.replace(/s$/, ' Search Results')
                            window.webix.$$('userspage').getChildViews()[1].define('header', label)
                            /*
                            if (label == 'Student Search Results') {
                                window.webix.$$('accounts').showColumn('student_first_name')
                                window.webix.$$('accounts').showColumn('student_last_name')
                            }
                            else {
                                window.webix.$$('accounts').hideColumn('student_first_name')
                                window.webix.$$('accounts').hideColumn('student_last_name')
                            }
                            */
                            window.webix.$$('accounts').clearAll()
                            window.webix.$$('students').clearAll()
                            if ( mode == 'Students' ) {
                                window.webix.$$('students').show()
                                window.webix.$$('accounts').hide()
                            }
                            else {
                                window.webix.$$('students').hide()
                                window.webix.$$('accounts').show()
                            }
                        }
                    },
                },
                cells: [
                    {
                        header: 'Search Accounts',
                        body: {
                            rows: [
                                studio_id ? {
                                    view: 'text',
                                    name: 'studio_id',
                                    id: 'searchusers_studio_id',
                                    value: studio_id,
                                    hidden: true,
                                } : { hidden: true },
                                {
                                    cols: [
                                        {
                                            view: 'text',
                                            label: 'Account Cell Phone',
                                            labelWidth: 150,
                                            id: 'searchusers_cell_phone',
                                            name: 'searchusers_cell_phone',
                                            width: 449,
                                        },
                                        {
                                            view: 'button',
                                            value: 'Clear Form',
                                            width: 100,
                                            click: () => {
                                                ;['searchusers_studio_id', 'searchusers_cell_phone', 'searchusers_first_name', 'searchusers_last_name', 'searchusers_city', 'searchusers_state'].forEach(field => {
                                                    if (window.webix.$$(field)) window.webix.$$(field).setValue('')
                                                    if (window.webix.$$(field.replace('users_', 'student_'))) window.webix.$$(field.replace('users_', 'student_')).setValue('')
                                                })
                                            }
                                        }
                                    ]
                                },
                                {
                                    cols: [
                                        {
                                            view: 'text',
                                            label: 'Last Name',
                                            id: 'searchusers_last_name',
                                            name: 'searchusers_last_name',
                                            width: 275,
                                        },
                                        {
                                            view: 'text',
                                            label: 'First Name',
                                            id: 'searchusers_first_name',
                                            name: 'searchusers_first_name',
                                            width: 275,
                                        },
                                    ]
                                },
                                {
                                    cols: [
                                        {
                                            view: 'text',
                                            label: 'City',
                                            id: 'searchusers_city',
                                            name: 'searchusers_city',
                                            width: 275,
                                        },
                                        {
                                            view: 'select',
                                            label: 'State',
                                            id: 'searchusers_state',
                                            name: 'searchusers_state',
                                            options: ['', ...STATES],
                                            width: 275,
                                        },
                                    ]
                                },
                                {
                                    view: 'button',
                                    width: 200,
                                    hotkey: 'enter',
                                    value: 'Search Accounts',
                                    click: async function () {
                                        doSearch('account')
                                    }
                                },
                            ]
                        }
                    },
                    {
                        header: 'Search Students',
                        body: {
                            rows: [
                                studio_id ? {
                                    view: 'text',
                                    name: 'studio_id',
                                    id: 'searchstudent_studio_id',
                                    value: studio_id,
                                    hidden: true,
                                } : { hidden: true },
                                {
                                    cols: [
                                        {
                                            view: 'text',
                                            label: 'Student Cell Phone',
                                            labelWidth: 150,
                                            id: 'searchstudent_cell_phone',
                                            name: 'searchstudent_cell_phone',
                                            width: 449,
                                        },
                                        {
                                            view: 'button',
                                            value: 'Clear Form',
                                            width: 100,
                                            click: () => {
                                                ;['searchusers_studio_id', 'searchusers_cell_phone', 'searchusers_first_name', 'searchusers_last_name', 'searchusers_city', 'searchusers_state'].forEach(field => {
                                                    if (window.webix.$$(field)) window.webix.$$(field).setValue('')
                                                    if (window.webix.$$(field.replace('users_', 'student_'))) window.webix.$$(field.replace('users_', 'student_')).setValue('')
                                                })
                                            }
                                        }
                                    ]
                                },
                                {
                                    cols: [
                                        {
                                            view: 'text',
                                            label: 'Last Name',
                                            id: 'searchstudent_last_name',
                                            name: 'searchstudent_last_name',
                                            width: 275,
                                        },
                                        {
                                            view: 'text',
                                            label: 'First Name',
                                            id: 'searchstudent_first_name',
                                            name: 'searchstudent_first_name',
                                            width: 275,
                                        },
                                    ]
                                },
                                {
                                    cols: [
                                        {
                                            view: 'text',
                                            label: 'City',
                                            id: 'searchstudent_city',
                                            name: 'searchstudent_city',
                                            width: 275,
                                        },
                                        {
                                            view: 'select',
                                            label: 'State',
                                            id: 'searchstudent_state',
                                            name: 'searchstudent_state',
                                            options: ['', ...STATES],
                                            width: 275,
                                        },
                                    ]
                                },
                                {
                                    view: 'button',
                                    width: 200,
                                    hotkey: 'enter',
                                    value: 'Search Students',
                                    click: async function () {
                                        doSearch('student')
                                    }
                                },
                            ]
                        }
                    },
                ],
            },
            {
                view: 'template',
                template: '<div style="font-weight: bold;">Users</div>',
                height: 25,
                borderless: true,
            },
            {
                    rows: [
                        {
                            view: 'toolbar',
                            height: 30,
                            cols: [
                                {
                                    view: 'button',
                                    value: 'Add Account',
                                    width: 120,
                                    click: function () {
                                        newUser(false, doSearch, studio_id)
                                    }
                                },
                            ]
                        },
                        {
                            view: 'datatable',
                            id: 'accounts',
                            resizeColumn: true,
                            sort: true,
                            width: window.innerWidth * .9,
                            onClick: {
                                navselect: function(e, id) {
                                    console.log('id', id)
                                    console.log('e', e)
                                    console.log('target', e.target)
                                    let row = window.webix.$$('accounts').getItem(id.row)
                                    const menuactions = {
                                        showUserStudios: () => showUserStudios(row),
                                        showStudents: () => students(row, studio_id),
                                        edit: () => {
                                                newUser(row.account_id, doSearch, studio_id) // modify = true
                                                //window.webix.$$('newUserForm').parse(row)
                                                //verifyForm('newUserForm')
                                        },
                                        showTransactions: () => accountTransactions(row),
                                        manageCard: () => creditCard(row),
                                    }
                                    let action = e.target.options[e.target.selectedIndex].getAttribute('action')
                                    //window.webix.message(action)
                                    console.log('action: ', action)
                                    if ( action ) {
                                        menuactions[action]()
                                        e.target.selectedIndex = 0
                                    }
                                }
                            },
                            columns: [
                                { id: 'studio_id', hidden: true },
                                { id: 'account_id', hidden: true },
                                {
                                    id: 'action',
                                    tooltip: false,
                                    //template: `<select class='navselect' onFocus="this.style.width=this.offsetWidth+20; this.size=this.options.length" onBlur="this.size=0">
                                    template: `<select class='navselect'>
                                            <option>Select an Option</option>
                                            <option action="edit">Edit Account</option>
                                            <option action="showStudents">Show Students</option>
                                            <option action="showTransactions">Show Transactions</option>
                                            <option action="manageCard">Manage Credit Card</option>
                                            <option action="showUserStudios">Show User's Studios</option>
                                        </select>`,
                                    width:155
                                },
                                {
                                    id: 'cell_phone',
                                    width: 120,
                                    sort: 'string',
                                    editor: 'text',
                                    pattern: { mask: "(###) ###-####", allow: /[0-9]/g },
                                    header: 'Cell Phone',
                                    tempate: function (row) {
                                        return formatPhone(row.cell_phone)
                                    }
                                },
                                {
                                    id: 'first_name',
                                    header: 'Account First Name',
                                    sort: 'string',
                                    editor: 'text',
                                    width: 160,
                                },
                                {
                                    id: 'last_name',
                                    header: 'Account Last Name',
                                    sort: 'string',
                                    editor: 'text',
                                    width: 160,
                                },
                                {
                                    id: 'city',
                                    header: 'City',
                                    sort: 'string',
                                    editor: 'text',
                                },
                                {
                                    id: 'state',
                                    header: 'State',
                                    sort: 'string',
                                    editor: 'select',
                                    options: STATES,
                                },
                                {
                                    id: 'email',
                                    header: 'Email',
                                    sort: 'string',
                                    editor: 'text',
                                    width: 200,
                                },
                                {
                                    id: 'tokens',
                                    header: 'CC On File?',
                                    sort: 'string',
                                    editor: 'text',
                                    width: 100,
                                    template: row => { return row.tokens ? 'Yes' : 'No' }
                                },
                            ],
                            data: [],
                        },
                        {
                            view: 'datatable',
                            id: 'students',
                            hidden: true,
                            resizeColumn: true,
                            sort: true,
                            width: window.innerWidth * .9,
                            onClick: {
                                navselect: function(e, id) {
                                    console.log('id', id)
                                    console.log('e', e)
                                    console.log('target', e.target)
                                    let row = window.webix.$$('students').getItem(id.row)
                                    const menuactions = {
                                        showUserStudios: () => showUserStudios(row),
                                        showStudents: () => students(row, studio_id),
                                        editaccount: () => newUser(row.account_id, doSearch, studio_id),
                                        editstudent: () => newStudent(row.student_id, row.account_id, row.studio_id, () => {
                                            doSearch('student')
                                            //sendCommand('searchPeople', search).then(resp => {
                                                //console.log('search results', resp)
                                                //window.webix.$$(mode.toLowerCase()).clearAll()
                                                //window.webix.$$(mode.toLowerCase()).parse(resp.result)
                                            //})
                                        }),
                                        showTransactions: () => accountTransactions(row),
                                        manageCard: () => creditCard(row),
                                    }
                                    let action = e.target.options[e.target.selectedIndex].getAttribute('action')
                                    //window.webix.message(action)
                                    console.log('action: ', action)
                                    if ( action ) {
                                        menuactions[action]()
                                        e.target.selectedIndex = 0
                                    }
                                }
                            },
                            columns: [
                                { id: 'studio_id', hidden: true },
                                { id: 'account_id', hidden: true },
                                { id: 'student_id', hidden: true },
                                {
                                    id: 'action',
                                    tooltip: false,
                                    //template: `<select class='navselect' onFocus="this.style.width=this.offsetWidth+20; this.size=this.options.length" onBlur="this.size=0">
                                    template: `<select class='navselect'>
                                            <option>Select an Option</option>
                                            <option action="editstudent">Edit Student</option>
                                            <option action="editaccount">Edit Account</option>
                                            <option action="showStudents">Show Students</option>
                                            <option action="showTransactions">Show Transactions</option>
                                            <option action="manageCard">Manage Credit Card</option>
                                            <option action="showUserStudios">Show User's Studios</option>
                                        </select>`,
                                    width:155
                                },
                                {
                                    id: 'cell_phone',
                                    width: 120,
                                    sort: 'string',
                                    editor: 'text',
                                    pattern: { mask: "(###) ###-####", allow: /[0-9]/g },
                                    header: 'Cell Phone',
                                    tempate: function (row) {
                                        return formatPhone(row.cell_phone)
                                    }
                                },
                                {
                                    id: 'first_name',
                                    header: 'Account First Name',
                                    sort: 'string',
                                    editor: 'text',
                                    hidden: true,
                                    width: 160,
                                },
                                {
                                    id: 'last_name',
                                    header: 'Account Last Name',
                                    sort: 'string',
                                    editor: 'text',
                                    hidden: true,
                                    width: 160,
                                },
                                {
                                    id: 'student_first_name',
                                    header: 'Student First Name',
                                    editor: 'text',
                                    sort: 'string',
                                    width: 160,
                                },
                                {
                                    id: 'student_last_name',
                                    header: 'Student Last Name',
                                    editor: 'text',
                                    sort: 'string',
                                    width: 160,
                                },
                                {
                                    id: 'city',
                                    header: 'City',
                                    sort: 'string',
                                    editor: 'text',
                                },
                                {
                                    id: 'state',
                                    header: 'State',
                                    sort: 'string',
                                    editor: 'select',
                                    options: STATES,
                                },
                                {
                                    id: 'email',
                                    header: 'Email',
                                    sort: 'string',
                                    editor: 'text',
                                    width: 200,
                                },
                                {
                                    id: 'tokens',
                                    header: 'CC On File?',
                                    sort: 'string',
                                    editor: 'text',
                                    width: 100,
                                    template: row => { return row.tokens ? 'Yes' : 'No' }
                                },
                                {
                                    id: 'studiobutton',
                                    width: 100,
                                    header: '',
                                    hidden: studio_id,
                                    template: function (row) {
                                        return "<div class='webix_el_button'><button class='webixtype_base'>Studios</button></div>";
                                    }
                                },
                                {
                                    id: 'editbutton',
                                    width: 100,
                                    header: '',
                                    template: function (row) {
                                        return "<div class='webix_el_button'><button class='webixtype_base'>Edit Account</button></div>";
                                    }
                                },
                                {
                                    id: 'ccbutton',
                                    width: 120,
                                    header: '',
                                    template: function (row) {
                                        return "<div class='webix_el_button'><button class='webixtype_base'>Credit Card</button></div>";
                                    }
                                },
                                {
                                    id: 'classesbutton',
                                    width: 120,
                                    header: '',
                                    template: function (row) {
                                        return "<div class='webix_el_button'><button class='webixtype_base'>Classes</button></div>";
                                    }
                                },
                            ],
                            data: [],
                            on: {
                                onItemClick: function (cell) {
                                    if (cell.column == 'action') return
                                    if (cell.column == 'studiobutton') {
                                        let row = window.webix.$$('students').getItem(cell.row)
                                        showUserStudios(row)
                                    }
                                    if (cell.column == 'studentbutton') {
                                        let row = window.webix.$$('students').getItem(cell.row)
                                        students(row, studio_id)
                                    }
                                    if (cell.column == 'ccbutton') {
                                        let row = window.webix.$$('students').getItem(cell.row)
                                        creditCard(row)
                                    }
                                    if (cell.column == 'classesbutton') {
                                        let row = window.webix.$$('students').getItem(cell.row)
                                        console.log('classesbutton', row)
                                        studentEnrollment(row, studio_id)
                                    }
                                }
                            }
                        },
                    ]
            }
        ]
    }
}
