import { sendCommand, formatDollar } from '../apis'

export async function accountTransactions(row) {
    console.log('row coming in is', row)
    if (! row.studio_id) {
        let rows = await sendCommand('getStudiosForAccountID', row)
        row.studio_id = rows.result.map(x => x.studio_id)
        console.log('filling in studio_id:', row.studio_id)
    }
    if (! row.studio_id || row.studio_id == []) {
        alert('This account is not registered with any studio.  Unable to pull transactions for this account.')
        return
    }
    sendCommand('getTransactionsForAccount', row).then(transactions => {
        console.log('transactions', transactions)
        let items = ( transactions && transactions.result && transactions.result.length ) ? transactions.result.map(row => {
            return {
                transaction_id: row.id,
                date: row.created_at,
                reason: row.line_items[0].note,
                amount: formatDollar(Math.round(row.total_money.amount / 100, 2)),
                status: row.state,
                card_type: row.tenders[0].card_details.card.card_brand,
                last_4: row.tenders[0].card_details.card.last_4,
            }
        }) : [{ reason: 'No Transactions Found'}]
        if (window.webix.$$('accountTransactions')) window.webix.$$('accountTransactions').destructor()
        window.webix.ui({
            view: 'window',
            id: 'accountTransactions',
            head: 'Account Transactions',
            close: true,
            escHide: true,
            position: 'center',
            width: window.innerWidth * .8,
            body: {
                view: "scrollview",
                scroll: "y",
                height: window.innerHeight - 75,
                body: {
                    rows: [
                        {
                            view: 'datatable',
                            data: items,
                            columns: [
                                {
                                    id: 'transaction_id',
                                    hidden: true,
                                },
                                {
                                    id: 'date',
                                    header: 'Transaction Date',
                                    width: 180,
                                    template: row => {
                                        let dt = new Date(row.date)
                                        return dt.toLocaleString()
                                    }
                                },
                                {
                                    id: 'reason',
                                    header: 'Description',
                                    width: 500,
                                },
                                {
                                    id: 'amount',
                                    header: 'Amount Charged',
                                },
                                {
                                    id: 'status',
                                    header: 'Charge Status',
                                    width: 130,
                                },
                                {
                                    id: 'card_type',
                                    header: 'Card Type',
                                },
                                {
                                    id: 'last_4',
                                    header: 'Last 4 Digits',
                                    width: 120,
                                },
                            ]
                        }
                    ]
                }
            }
        }).show()
    })
}
