import { login } from './login'
import { logout } from './logout'
import {adminStudios} from './studios/adminStudios'
import {adminAccounts} from './accounts/manageAccounts'
import { Profiler } from 'react'

const underConstruction = () => {
    return {
        view: 'align',
        width: 300,
        align: 'middle,center',
        body: {
            width: 155,
            height: 30,
            borderless: true,
            view: 'template',
            template: 'Under Construction'
        }
    }
}

export const MENU_ITEMS = {
    'Admin Menu': [
        ['Studios', adminStudios],
        ['Accounts', adminAccounts],
        ['Transactions', underConstruction],
        ['Login As Account', underConstruction],
    ],
    'Owner Menu': [
        ['Manage Studios', underConstruction],
        ['Manage Locations', underConstruction],
        ['Manage Seasons', underConstruction],
        ['Manage Levels', underConstruction],
        ['Manage Accounts', underConstruction],
        ['Manage Students', underConstruction],
        ['Manage Classes', underConstruction],
        ['View Schedule', underConstruction],
        ['Manage Private Lessons', underConstruction],
        ['Fee Management', underConstruction],
        ['Discounts', underConstruction],
        ['Manage Time Off', underConstruction],
        ['Billing and Payments', underConstruction],
        ['Reports', underConstruction],
        ['Set Up Reminders', underConstruction],
        ['Notifications', underConstruction],
    ],
    'Instructor Menu': [
        ['My Schedule', underConstruction],
        ['View Roster', underConstruction],
        ['Manage Private Lessons', underConstruction],
        ['Notifications', underConstruction],
        ['Set Up Reminders', underConstruction],
        ['Request Time Off', underConstruction],
    ],
    'User Menu': [
        ['Manage Students', underConstruction],
        ['Register With Studio', underConstruction],
        ['Register for Classes', underConstruction],
        ['View Student Schedule', underConstruction],
        ['View Studio Schedule', underConstruction],
        ['Payment History', underConstruction],
        ['Manage Payment Method', underConstruction],
        ['Set Up Reminders', underConstruction],
    ],
    'Guest Menu': [
        ['Log In', underConstruction],
    ],
    'Account': [
        ['My Account', underConstruction],
        ['Log Out', logout]
    ],
}
