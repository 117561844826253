import { sendCommand } from './apis'


export function findStudio(account_id, button_label, actioncb) {
    if (window.webix.$$('findStudio')) window.webix.$$('findStudio').destructor()

    window.webix.ui({
        view: 'window',
        position: 'center',
        height: window.innerHeight * .8,
        width: window.innerWidth * .8,
        head: 'Search for Studio',
        close: true,
        escHide: true,
        id: 'findStudio',
        body: {
            view: 'accordion',
            height: window.innerHeight * .8,
            multi: true,
            rows: [
                {
                    view: 'accordionitem',
                    id: 'criteria',
                    header: 'Search Parameters',
                    body: {
                        view: 'form',
                        id: 'studioSearchForm',
                        elements: [
                            {
                                view: 'text',
                                label: 'Studio Name',
                                id: 'studio_name',
                                name: 'studio_name',
                                width: 275,
                            },
                            {
                                cols: [
                                    {
                                        view: 'text',
                                        label: 'Studio City',
                                        id: 'studio_city',
                                        name: 'studio_city',
                                        width: 275,
                                    },
                                    {
                                        view: 'text',
                                        label: 'Studio State',
                                        id: 'studio_state',
                                        name: 'studio_state',
                                        width: 275,
                                    },
                                ]
                            },
                            {
                                cols: [
                                    {
                                        view: 'checkbox',
                                        label: 'Active',
                                        id: 'active',
                                        name: 'active',
                                        width: 275,
                                    },
                                    {
                                        view: 'checkbox',
                                        label: 'Open Enrollment',
                                        id: 'open_enrollment',
                                        name: 'open_enrollment',
                                        width: 275,
                                    },
                                ]
                            },
                            {
                                view: 'button',
                                width: 200,
                                hotkey: 'enter',
                                value: 'Search',
                                click: function () {
                                    sendCommand('searchStudios', window.webix.$$('studioSearchForm').getValues()).then(resp => {
                                        console.log('search results for studio', resp)
                                        if (resp.success) {
                                            window.webix.$$('studioTable').clearAll()
                                            window.webix.$$('studioTable').parse(resp.result.studios)
                                            window.webix.$$('criteria').collapse()
                                        }
                                        else {
                                            window.webix.message({type: 'error', text: resp.err_msg})
                                        }
                                    })
                                }
                            },
                        ]
                    },
                },
                {
                    view: 'accordionitem',
                    header: 'Results',
                    body: {
                        rows: [
                            {
                                view: 'datatable',
                                width: window.innerWidth * .8,
                                id: 'studioTable',
                                resize: true,
                                sort: true,
                                data: [],
                                headerRowHeight: 25,
                                columns: [
                                    { id: 'studio_id', hidden: true },
                                    {
                                        id: 'selected',
                                        width: 30,
                                        header: '',
                                        template: '{common.checkbox()}'
                                    },
                                    { id: 'studio_name', width: 150, header: 'Studio Name' },
                                    { id: 'studio_city', width: 200, header: 'City' },
                                    { id: 'studio_state', width: 200, header: 'State' },
                                    { id: 'active', width: 200, header: 'Active' },
                                    { id: 'open_enrollment', width: 200, header: 'Open Enrollment' },
                                ],
                            },
                            {
                                view: 'button',
                                value: button_label,
                                width: 150,
                                click: function() {
                                    let rows = []
                                    window.webix.$$('studioTable').data.each(rec => {
                                        if ( rec.selected ) {
                                            delete rec.selected
                                            rows.push(rec)
                                        }
                                    })
                                    window.webix.$$('findStudio').destructor()
                                    if ( actioncb ) actioncb(rows)
                                }
                            }
                        ]
                    }
                }
            ]
        }
    }).show()
}